<template>
  <v-container>
    <v-card class="mb-5">
      <v-card-title>クーポン生成</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="5"><v-subheader>付与pt</v-subheader></v-col>
          <v-col cols="7"><v-text-field v-model="addingPoints" single-line type="number" hide-details suffix="pt"></v-text-field></v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="5"><v-subheader>有効期限</v-subheader></v-col>
          <v-col><v-text-field v-model="expirationTime" single-line type="number" suffix="時間後"></v-text-field></v-col>
          <v-col cols="auto"><v-btn @click="applyExpirationTime">適用</v-btn></v-col>
          <v-col><v-text-field v-model="expiration"></v-text-field></v-col>
        </v-row>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col cols="auto"><v-btn class="white--text" color="cyan" @click="createCoupon">クーポン生成</v-btn></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title>クーポンガチャ生成</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="5"><v-subheader>付与pt</v-subheader></v-col>
          <v-col cols="7"><v-text-field v-model="couponGachaAddingPoints" single-line type="number" hide-details suffix="pt"></v-text-field></v-col>
        </v-row>
        <v-row dense>
          <v-col cols="5"><v-subheader>当選確率</v-subheader></v-col>
          <v-col cols="7"><v-text-field v-model="hitRate" single-line type="number" step="any" hide-details suffix="【%】"></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="5"><v-subheader>当選者数の上限</v-subheader></v-col>
          <v-col cols="7"><v-text-field v-model="couponGachaLot" single-line type="number" hide-details suffix="【人】"></v-text-field></v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="5"><v-subheader>有効期限</v-subheader></v-col>
          <v-col><v-text-field v-model="couponGachaExpirationTime" single-line type="number" suffix="時間後"></v-text-field></v-col>
          <v-col cols="auto"><v-btn @click="applyCouponGachaExpirationTime">適用</v-btn></v-col>
          <v-col><v-text-field v-model="couponGachaExpiration"></v-text-field></v-col>
        </v-row>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col cols="auto"><v-btn class="white--text" color="cyan" @click="createCouponGacha">クーポンガチャ生成</v-btn></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        クーポン一覧
        <v-spacer></v-spacer>
        <v-btn color="error" v-if="selectedCoupons.length>0" @click="deleteCoupons">削除</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table v-model="selectedCoupons" :headers="headers" :items="coupons" item-key="coupon_id" show-select></v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from '../store'
import Api from '@/modules/api'

export default {
  name: 'AdminCoupon',
  data(){
    return {
      addingPoints: null,
      expirationTime: null,
      couponGachaExpirationTime: null,
      expiration: null,
      couponGachaAddingPoints: null,
      hitRate: null,
      couponGachaLot: null,
      couponGachaExpiration: null,
      headers: [
        {text:'ID',value:'coupon_id'},
        {text:'pulish',value:'publish'},
        {text:'タイプ',value:'coupon_type'},
        {text:'付与pt',value:'add_points'},
        {text:'有効期限',value:'expiration'},
        {text:'user_id',value:'user_id'},
        {text:'コード',value:'coupon_code'},
      ],
      selectedCoupons: [],
    }
  },
  computed: {
    coupons: function(){
      const coupons = store.getters.coupons
      if(coupons == null) return []
      return coupons
    }
  },
  methods: {
    applyExpirationTime: function(){
      let dt = new Date()
      dt.setHours(dt.getHours()+Number(this.expirationTime))
      this.expiration = this.formatDate(dt)
    },
    applyCouponGachaExpirationTime: function(){
      let dt = new Date()
      dt.setHours(dt.getHours()+Number(this.couponGachaExpirationTime))
      this.couponGachaExpiration = this.formatDate(dt)
    },
    formatDate: function(date){
      const Y = date.getFullYear();
      const M = date.getMonth() + 1;
      const D = date.getDate();
      const YYYY = Y.toString();
      const MM = ("00" + M).slice(-2);
      const DD = ("00" + D).slice(-2);
      const h = date.getHours();
      const m = date.getMinutes();
      const s = date.getSeconds();
      const hh = ("00" + h).slice(-2);
      const mm = ("00" + m).slice(-2);
      const ss = ("00" + s).slice(-2);
      return YYYY+'-'+MM+'-'+DD+' '+hh+':'+mm+':'+ss;
    },
    createCoupon: function(){
      if([this.addingPoints,this.expiration].every(value => value!=null)){
        Api.postCoupon(0,Number(this.addingPoints),this.expiration)
      }
    },
    createCouponGacha: function(){
      if([this.couponGachaAddingPoints,this.hitRate,this.couponGachaLot,this.couponGachaExpiration].every(value => value!=null)){
        Api.postCouponGacha(Number(this.couponGachaAddingPoints),Number(this.hitRate),Number(this.couponGachaLot),this.couponGachaExpiration)
      }
    },
    deleteCoupons: function(){
      Api.deleteCoupon(this.selectedCoupons)
    },
  }
}
</script>