import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    jwtToken: null,
    cardsStock: null,
    oripas: null,
    oripasTemplate: null,
    delivery_history: null,
    coupons: null,
    cardMaster: null,
    cardsDelivery: null,
  },
  getters: {
    jwtToken: state => state.jwtToken,
    oripas: state => state.oripas,
    cardsStock: state => state.cardsStock,
    oripasTemplate: state => state.oripasTemplate,
    delivery_history: state => state.delivery_history,
    coupons: state => state.coupons,
    cardMaster: state => state.cardMaster,
    cardsDelivery: state => state.cardsDelivery,
  },
  mutations: {
    setJwtToken(state, jwtToken){
      state.jwtToken = jwtToken
    },
    setOripas(state,oripas){
      state.oripas = oripas
    },
    updateCardsStock(state, payload){
      state.cardsStock = payload
    },
    setOripasTemplate(state, oripasTemplate){
      state.oripasTemplate = oripasTemplate
    },
    setDeliveryHistory(state, delivery_history){
      state.delivery_history = delivery_history
    },
    setCoupons(state, coupons){
      state.coupons = coupons
    },
    setCardMaster(state, cardMaster){
      state.cardMaster = cardMaster
    },
    setCardsDelivery(state, cardsDelivery){
      state.cardsDelivery = cardsDelivery
    }
  },
  actions: {
  },
  modules: {
  }
});

export default store;