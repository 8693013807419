import axios from 'axios'
import store from '../store'

const baseUrl = process.env.VUE_APP_API_ORIGIN

export default {
  getUserAll: function(){
    console.log('=====get_user_all=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/user/all'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getOripaAll: function(){
    console.log('=====get_oripa_all=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/all'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        store.commit('setOripas',res.data)
        console.log(res.data)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getOripaOrders: function(oripa_id){
    console.log('=====get_oripa_orders=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/' + oripa_id
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        console.log(res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCardMaster: function(){
    console.log('=====get_card_master=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/cards/master'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        let cardMaster = res["data"]
        for(var i = 0;i < cardMaster.length;i++){
            cardMaster[i]["rate"] = (Math.abs(cardMaster[i]["price"] - cardMaster[i]["trim_avg_price"])/cardMaster[i]["price"]).toFixed(4)
            cardMaster[i]["rush_rate"] = (Math.abs(cardMaster[i]["rush_price"] - cardMaster[i]["trim_avg_price"])/cardMaster[i]["rush_price"]).toFixed(4)
            cardMaster[i]["price_diff"] = Math.abs(cardMaster[i]["price"] - cardMaster[i]["trim_avg_price"])
        }
        store.commit('setCardMaster',cardMaster)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCardScraping: function(){
    console.log('=====get_card_scraping=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/cards/scraping'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getOripasTemplate: function(){
    console.log('=====get_oripa_template=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/template'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        store.commit('setOripasTemplate',res.data)
        console.log(res.data)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCardsStock: function(){
    console.log('=====get_cards_stock=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/cards/stock'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{
        headers: headers,
        params: {
          card_type: 'all'
        }
      }).then(res => {
        store.commit('updateCardsStock',res.data)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCardsDelivery: function(){
    console.log('=====get_cards_delivery=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/cards/delivery'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        console.log(res.data)
        store.commit('setCardsDelivery',res.data)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDeliveryHistory: function(){
    console.log("=====get_delivery_history_all======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/delivery_history/all"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{
        headers: headers
      }).then(res => {
        store.commit('setDeliveryHistory',res.data)
        console.log(res.data)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCoupon: function(){
    console.log("=====get_coupon=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/coupon"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{
        headers: headers
      }).then(res => {
        store.commit('setCoupons',res.data)
        console.log(res.data)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postOripaTemplate: function(oripa_name,subtitle,all_lot,point,prizeCards,blankCards,publish,design,hashtags,detail,oripaType,max_draw){
    console.log('=====post_oripa_template=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/template'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        oripa_name: oripa_name,
        subtitle: subtitle,
        all_lot: all_lot,
        price: point,
        prize_cards: prizeCards,
        blank_cards: blankCards,
        publish: publish,
        design: design,
        hashtags: hashtags,
        detail: detail,
        oripa_type: oripaType,
        max_draw: max_draw
      },{
        headers: headers
      }).then(res => {
        this.getOripasTemplate()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postCardMaster: function(card_id,pack_number,card_list_number,card_name,card_rarity,return_point,price,publish,options,pre_image_url){
    console.log('=====post_card_master=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/cards/master'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        card_id: card_id,
        pack_number: pack_number,
        card_list_number: card_list_number,
        card_name:card_name,
        card_rarity:card_rarity,
        return_point:return_point,
        price:price,
        publish:publish,
        options:options,
        pre_image_url:pre_image_url
      },{
        headers: headers
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postOripaAll: function(oripa_name,subtitle,all_lot,point,prizeCards,blankCards,dateOpen,timeOpen,dateSale,timeSale,publish,design,hashtags,detail,oripaType,max_draw){
    console.log('=====post_oripa_all=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/all'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        oripa_name: oripa_name,
        subtitle: subtitle,
        all_lot: all_lot,
        price: point,
        prize_cards: prizeCards,
        blank_cards: blankCards,
        open_ts: dateOpen+' '+timeOpen+':00',
        sale_ts: dateSale+' '+timeSale+':00',
        publish: publish,
        design: design,
        hashtags: hashtags,
        detail: detail,
        oripa_type: oripaType,
        max_draw: max_draw
      },{
        headers: headers
      }).then(res => {
        this.getOripaAll()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postCoupon: function(coupon_type,add_points,expiration){
    console.log("=====post_coupon=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/coupon'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        coupon_type: coupon_type,
        add_points: add_points,
        expiration: expiration
      },{
        headers: headers
      }).then(res => {
        this.getCoupon()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postCouponGacha: function(adding_points,hit_rate,lot,expiration){
    console.log("=====post_coupon=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/coupon/gacha'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        add_points: adding_points,
        hit_rate: hit_rate,
        lot: lot,
        expiration: expiration
      },{
        headers: headers
      }).then(res => {
        this.getCoupon()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteOripaTemplate: function(oripa_template_id){
    console.log('=====delete_oripa_template=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/template'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.delete(url,{
        headers: headers,
        data: {
          oripa_template_id: oripa_template_id
        }
      }).then(res => {
        this.getOripasTemplate()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  putCardMaster: function(card){
    console.log("=====put_card_master=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/cards/master'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.put(url,card,{
        headers: headers
      }).then(res => {
        this.getCardsStock()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  putOripa: function(oripa_id,dateOpen,timeOpen,dateSale,timeSale,publish,design,hashtags,detail,oripaType){
    console.log("=====put_oripa=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/' + oripa_id
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.put(url,{
        open_ts: dateOpen+' '+timeOpen+':00',
        sale_ts: dateSale+' '+timeSale+':00',
        publish: publish,
        design: design,
        hashtags: hashtags,
        detail: detail,
        oripa_type: oripaType
      },{
        headers: headers
      }).then(res => {
        this.getOripaAll()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  putDeliveryHistory: function(delivery_id,derivery_status,delivery_date,responsible_person,delivery_company,tracking_number){
    console.log("=====update_delivery_history======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/delivery_history"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.put(url,{
        delivery_id: delivery_id,
        delivery_status: derivery_status,
        delivery_date: delivery_date,
        responsible_person: responsible_person,
        delivery_company: delivery_company,
        tracking_number: tracking_number
      },{
        headers: headers
      }).then(res => {
        this.getDeliveryHistory()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteOripa: function(oripa_id){
    console.log('=====delete_oripa=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/oripa/' + oripa_id
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.delete(url,{
        headers: headers
      }).then(res => {
        this.getOripaAll()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteCoupon: function(coupons){
    console.log('=====delete_coupon=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + '/coupon'
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.delete(url,{
        headers: headers,
        data: {
          coupons: coupons
        }
      }).then(res => {
        this.getCoupon()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
// comment