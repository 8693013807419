<template>
  <v-container style="max-width:600px;" class="mt-15">
    <h2 class="text-center">Login</h2>
    <p class="mt-10">メールアドレス</p>
    <v-text-field v-model="email" solo></v-text-field>
    <p>パスワード</p>
    <v-text-field type="password" v-model="pass" solo></v-text-field>
    <v-btn class="mt-5 white--text" color="black" block @click="login">ログイン</v-btn>
  </v-container>
</template>

<script>
import store from '../store'
import Api from '@/modules/api'
import Utils from '@/modules/utils'

export default {
  name: 'Login',
  data(){
    return {
      email: '',
      pass: '',
    }
  },
  methods: {
    login: function(){
      Utils.login(this.email,this.pass).then(res => {
        console.log(res)
        const jwtToken = res.idToken.jwtToken
        console.log("jwtToken: ",jwtToken)
        this.$store.commit('setJwtToken',jwtToken)
        this.$router.push('/createOripa')
      })
      // this.$cognito.signin(this.email,this.pass)
      // .then(result => {
      //   console.log(result)
      //   const jwtToken = result.idToken.jwtToken
      //   console.log("jwtToken: ",jwtToken)
      //   this.$store.commit('setJwtToken',jwtToken)
      //   this.$router.push('/createOripa')
      // })
    }
  }
}
</script>