<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn class="ma-1" @click="openDialogNew">カードマスタ追加</v-btn>
      </v-col>
    </v-row>

    <v-row class="pa-4">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="検索" sigle-line hide-details/>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="filteredCardMaster" :search="search" disable-pagination hide-default-footer fixed-header height="1000" @click:row="selectCard">
          <template v-slot:item.image="{item}">
            <v-img :src="item.image" class="ma-1" width="40px"></v-img>
          </template>
          <template v-slot:item.id="{item}">
            【{{ item.card_rarity }}】{{ item.card_list_number }} {{ item.pack_number }}〘{{ item.options }}〙
          </template>
          <template v-slot:item.point-price="{item}">
            {{ item.return_point }}pt / {{ item.price }}円
          </template>
          <template v-slot:item.avg_price_and_trim="{item}">
            {{ item.avg_price }}円【{{ item.trim_avg_price }}円】
          </template>
          <template v-slot:item.rush_price="{item}">
            {{ item.rush_price }}円
          </template>
          <template v-slot:item.diff="{item}">
            {{ diffPrice(item) }}円(<span :class="{'red lighten-2':diffPercent(item)>10,'blue lighten-2':diffPercent(item)<-10}">{{diffPercent(item) }}</span>%)
          </template>
          <template v-slot:item.stocks="{item}">
            {{ item.sum_stock }}【{{ item.rush_stock }}】
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogNew" max-width="60%" :retain-focus="false">
        <v-card>
            <v-card-title>新カード追加</v-card-title>
            <div class="ma-5">
                <v-row align="center"> 
                  <v-col class="ma-3">
                    <v-text-field hide-details v-model="image_url" label="画像URLから追加" placeholder="https://www.pokemon-card.com/assets/images/card_images/large/***/*********.jpg"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn @click="importFromUrl">インポート</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider class="pa-3"></v-divider>
                </v-row>
                <v-row>
                  <v-col cols=6>
                    <v-text-field hide-details v-model="card_id" label="card_id" hint="card_idは自動的に計算されます．" class="readonly-field" readonly></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details v-model="pack_number" label="pack_number"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details v-model="card_list_number" label="card_list_number"> </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=5>
                    <v-text-field hide-details v-model="card_name" label="card_name"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details v-model="card_rarity" label="card_rarity"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details v-model="return_point" label="return_point"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details v-model="price" label="price"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=8>
                    <v-text-field hide-details v-model="pre_image" label="pre_image_url"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details v-model="publish" label="publish"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field hide-details v-model="options" label="options"></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="closeDialogNew">キャンセル</v-btn>
                  <v-btn @click="postCardMaster">追加</v-btn>
                </v-card-actions>
                <v-row>
                  <v-col cols=2>
                    <v-img :src="pre_image"></v-img>
                  </v-col>
                  <v-col>
                    <v-row>・<a href="https://www.pokemon-card.com/card-search/index.php">ポケカ公式サイト</a>より画像のURLをコピペしてインポートすることで，一部の項目を自動的に追加できる．</v-row>
                  </v-col>
                </v-row>
                <v-row>
                    <v-card-text>
                    <div class="table-container">
                        <table class="dialog">
                        <thead>
                            <tr>
                                <th><b>項目名</b></th>
                                <th>説明</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>card_id</b></td>
                                <td>pack_numberとcard_list_numberのスラッシュ前までとoptionsを結合したもので，自動で計算される．</td>
                            </tr>
                            <tr>
                                <td><b>pack_number</b></td>
                                <td>カードの左下に黒背景白文字で記載されているパック番号．カード画像のURLにおける後ろから2番目の項目．</td>
                            </tr>
                            <tr>
                                <td><b>card_list_number</b></td>
                                <td>カードの左下に000/000の形式で書かれている通し番号．</td>
                            </tr>
                            <tr>
                                <td><b>card_name</b></td>
                                <td>カードの名前．</td>
                            </tr>
                            <tr>
                                <td><b>card_rarity</b></td>
                                <td>カード左下に記載のカードのレアリティ．</td>
                            </tr>
                            <tr>
                                <td><b>return_point</b></td>
                                <td>カードを還元する時にもらえるポイント．priceより少なく設定する．</td>
                            </tr>
                            <tr>
                                <td><b>price</b></td>
                                <td>カードの値段．現在の相場を設定する．</td>
                            </tr>
                            <tr>
                                <td><b>pre_image_url</b></td>
                                <td>圧縮前の元画像．この画像を規定のサイズに圧縮後S3に保存する．公式に画像がある場合はそれを，ない場合はカードラッシュから引用する．</td>
                            </tr>
                            <tr>
                                <td><b>publish</b></td>
                                <td>1に設定するとオリパで使用できるようになる．0だとオリパで使用できなくなる．</td>
                            </tr>
                            <tr>
                                <td><b>options</b></td>
                                <td>"PSA10"等の追加情報を表す．付与した場合，idを一意に保つためpost時にunixtimeを付与する．</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </v-card-text>
                </v-row>
            </div>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/modules/api'
import store from '../store'
export default {
    name: 'CardStatisticsList',
    data(){
      return {
          headers: [
            { value:'image'},
            { value:'id'},
            { value:'card_name'},
            { value:'point-price'},
            // { text: 'Pack Number',value: 'pack_number',},
            // { text: 'Card Number', value: 'card_list_number' },
            // { text: 'Card Rarity',value: 'card_rarity'},
            // { text: 'Name',value: 'card_name' },
            // { text: 'Return Point',value: 'return_point'},
            // { text: 'Price',value: 'price'},
            // { text: '平均相場',value: 'avg_price'},
            // { text: 'Trimed Current Price (Avg)',value: 'trim_avg_price'},
            
            // { text: 'Relative Error (%)',value: 'rate'},
            // { text: '誤差',value: 'price_diff'},
            { text: '相場(CR)',value: 'rush_price'},
            { text: '誤差', value: 'diff'},
            // { text: '相対誤差(CR)',value: 'rush_rate'},
            { text: '在庫【CR】', value: 'stocks'},
            { text: '平均相場【外れ値除外】', value: 'avg_price_and_trim'},
            // { text: 'Sum Stock',value: 'sum_stock'},
            // { text: 'Card Rush Stock',value: 'rush_stock'},
          ],
          // cardMaster: [],
          cardScraping: [],
          dialogNew: false,
          image_url: "",
          pack_number: "",
          card_list_number: "",
          card_rarity: "",
          card_name: "",
          pre_image: "",
          return_point: "",
          price: "",
          publish: 1,
          options: "-",
          search: '',
      }
    },
    mounted: function(){
        // this.getCardMaster()
        // this.getCardScraping()
    },
    computed: {
      card_id: function(){
          if(this.options == "" || this.options == "-"){
              return this.pack_number+"_"+this.card_list_number.split("/")[0]
          }else{
              const now = new Date();
              var unixtime = Math.floor(now.getTime() / 1000);
              return this.pack_number+"_"+this.card_list_number.split("/")[0]+"_"+this.options+"_"+unixtime.toString()
          }
      },
      cardMaster: function(){
        const cardMaster = store.getters.cardMaster
        if(cardMaster == null) return []
        return cardMaster
      },
      filteredCardMaster: function(){
        let filtered = this.cardMaster
        filtered.sort((a,b)=>{
          return (a.price > b.price) ? -1 : 1
        })
        return filtered
      },
    },
    methods: {
        // getCardMaster: function(){
        //     Api.getCardMaster()
        //     .then(function(res){
        //         var cardMaster = res["data"]
        //         for(var i = 0;i < cardMaster.length;i++){
        //             cardMaster[i]["rate"] = (Math.abs(cardMaster[i]["price"] - cardMaster[i]["trim_avg_price"])/cardMaster[i]["price"]).toFixed(4)
        //             // if(cardMaster[i]["rate"] == 1.0000){
        //             //     cardMaster[i]["rate"] = 0.0
        //             // }
        //             cardMaster[i]["rush_rate"] = (Math.abs(cardMaster[i]["rush_price"] - cardMaster[i]["trim_avg_price"])/cardMaster[i]["rush_price"]).toFixed(4)
        //             cardMaster[i]["price_diff"] = Math.abs(cardMaster[i]["price"] - cardMaster[i]["trim_avg_price"])
        //         }
        //         this.cardMaster = cardMaster
        //         console.log(this.cardMaster)
        //     }.bind(this))
        //     .catch(function(err){
        //         console.log(err)
        //     }.bind(this))
        // },

        // getCardScraping: function(){
        //     Api.getCardScraping()
        //     .then(function(res){
        //         this.cardScraping = res["data"]
        //         console.log(this.cardScraping)
        //         var ato = this.arrayToObject(this.cardScraping,"card_id")
        //         this.objectToArray(ato)
        //     }.bind(this))
        //     .catch(function(err){
        //         console.log(err)
        //     }.bind(this))
        // },
        diffPrice: function(item){
          if (item["rush_price"] == null || item["price"] == null) return '???'
          return item["rush_price"] - item["price"]
        },
        diffPercent: function(item){
          if (item["rush_price"] == null || item["price"] == null) return '???'
          return Math.round((item["rush_price"] - item["price"])/item["price"]*100*100)/100
        },
        sortPrice: function(){
          this.prizeCards.sort(function(a,b){return (a.price > b.price) ? -1 : 1;})
        },
        selectCard(item){
            console.log(item)
            this.$router.push({name: 'cardStatistics', params: {item: item}})
        },
        openDialogNew: function(){
            this.dialogNew = true
        },
        closeDialogNew: function(){
            this.dialogNew = false
        },
        importFromUrl: function(){
            var url_splitted = this.image_url.split("/")
            var l = url_splitted.length
            this.pack_number = url_splitted[l-2]
            this.card_id = url_splitted[l-2] + "_" + url_splitted[l-1].split(".")[0]
            this.pre_image = this.image_url
        },
        postCardMaster: function(){
            Api.postCardMaster(
                this.card_id,
                this.pack_number,
                this.card_list_number,
                this.card_name,
                this.card_rarity,
                this.return_point,
                this.price,
                this.publish,
                this.options,
                this.pre_image
            ).then(function(res){
                console.log(res)
                this.closeDialogNew()
            }.bind(this))
            .catch(function(err){
                console.log(err)
                alert(err);
            }.bind(this))
        },
        // objectToArray: function(obj){
        //     var arr = [];

        //     for (var key in obj){
        //         console.log(key)
        //         arr.push(obj[key])
        //     }

        //     console.log(arr)
        //     return arr
        // },
        // arrayToObject: function(arr,key_name){
        //     var obj = new Object;

        //     for (var i = 0; i < arr.length; i++) {
        //         var k = arr[i][key_name];
        //         var v = arr[i]
        //         obj[k] = v;
        //     }

        //     console.log(obj);
        //     return obj
        // },
        // arrayToObjectAvgPrice: function(){
        //     var obj = new Object;

        //     for (var i = 0; i < arr.length; i++) {
        //         var k = arr[i][key_name];
        //         var v = arr[i]
        //         obj[k] = v;
        //     }

        //     console.log(obj);
        //     return obj
        // }
    }

}
</script>

<style>
.readonly-field {
  background-color: #f5f5f5;
  color: #666;
}

.table-container {
  max-height: 400px;
  overflow: auto;
}

thead {
  position: sticky;
  top: 0;
  background-color: white;
}


</style>