<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-4">
          <v-card-title>
            <v-text-field
              v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>フィルター</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-date-picker v-model="dates" range></v-date-picker>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="dates" label="Dates" prepend-icon="mdi-calendar" readonly></v-text-field>
                      <v-btn-toggle v-model="deliveryStatusForFilter" multiple color="cyan">
                        <v-btn>発送準備</v-btn>
                        <v-btn>発送完了</v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="6"><v-text-field v-model="dateRangeText" label="Date range" prepend-icon="mdi-calendar" readonly></v-text-field></v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto"><v-btn @click="printAll" icon><v-icon>mdi-printer-outline</v-icon></v-btn></v-col>
              <v-col cols="auto"><v-btn @click="dialogDeliveryCards=true" icon><v-icon>mdi-cards</v-icon></v-btn></v-col>
            </v-row>
            
            <v-data-table
              v-model="selected" :headers="headers" :items="filteredDeliveryHistory" item-key="delivery_id"
              :search="search" :single-select="true" show-select class="elevation-1"
              :items-per-page="-1" hide-default-header hide-default-footer
            >
              <template v-slot:item.delivery_status="{ item }">
                <v-chip v-if="item.delivery_status==0" color="red" dark>発送準備</v-chip>
                <v-chip v-else-if="item.delivery_status==1" color="green" dark>発送完了</v-chip>
              </template>
              <template v-slot:item.create_ts="{ item }">
                <v-chip label outlined color="cyan">{{ item.create_ts.split('T')[0] }}</v-chip>
              </template>
              <template v-slot:item.name="{item}">
                <v-chip label outlined color="blue">{{ item.last_name }} {{ item.first_name }}</v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card v-if="selected.length > 0">
          <v-card-subtitle>delivery id: {{ selected[0].delivery_id }}</v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="selectDelivery">発送完了する</v-btn>
          </v-card-actions>
          <v-card-text>
            <p class="mb-0">user id: {{ selected[0].user_id }}</p>
            <p class="mb-0">{{ selected[0].mail_address }}</p>
            <p>{{ selected[0].last_name }} {{ selected[0].first_name }} / {{ selected[0].last_name_kana }} {{ selected[0].first_name_kana }}</p>
            <p class="mb-0">TEL: {{ selected[0].tell_number }}</p>
            <p class="mb-0">〒{{ selected[0].post_code }}</p>
            <p>{{ selected[0].prefectures }}{{ selected[0].municipalities }}{{ selected[0].address }}{{ selected[0].building }}</p>
            <p>合計{{ selected[0].orders.length }}枚</p>
            <v-data-table :headers="orders_headers" :items="selected[0].orders" item-key="order_id"
              :items-per-page="-1" hide-default-header hide-default-footer>
              <template v-slot:item.image="{ item }" >
                <v-img :src="item.image" class="ma-1" width="60px"></v-img>
              </template>
            </v-data-table>
          </v-card-text>
          
          <v-dialog v-model="dialog">
            <v-card>
              <v-container>
                <v-card-title>delivery id: {{ selected[0].delivery_id }}</v-card-title>
                <v-card-subtitle>{{ selected[0].mail_address }} {{ selected[0].last_name }} {{ selected[0].first_name }} / {{ selected[0].last_name_kana }} {{ selected[0].first_name_kana }}</v-card-subtitle>
                <v-card-text>
                  <p class="mb-0">TEL: {{ selected[0].tell_number }}</p>
                  <p>〒{{ selected[0].post_code }} {{ selected[0].prefectures }}{{ selected[0].municipalities }}{{ selected[0].address }}{{ selected[0].building }}</p>
                  <v-form>
                    <v-row>
                      <v-col align="center" cols="6">
                        <v-date-picker v-model="deliveryDate" min="2023-01-01"></v-date-picker>
                      </v-col>
                      <v-col cosl="6">
                        <v-row>
                          <v-col cols="6">
                            <v-select v-model="deliveryStatus" :items="status" item-text="text" item-value="value"
                            label="発送状態" hide-details
                            >
                              <template v-slot:prepend>
                                <v-icon v-if="deliveryStatus==0" color="red">mdi-truck-outline</v-icon>
                                <v-icon v-else-if="deliveryStatus==1" color="green">mdi-truck-check-outline</v-icon>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field v-model="deliveryDate" label="発送日" prepend-icon="mdi-calendar" readonly hide-details></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-select v-model="responsiblePerson" :items="people" label="発送者"
                              hide-details prepend-icon="mdi-account-outline"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-select v-model="deliveryCompany" :items="companies" label="配送会社"
                              hide-details prepend-icon="mdi-truck-outline"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field v-model="trackingNumber" label="追跡番号" hide-details prepend-icon="mdi-identifier"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="updateDeliveryHistoryWrap" outlined>配送を完了</v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDeliveryCards" fullscreen>
      <v-card>
        <v-card-actions>
          <v-btn @click="dialogDeliveryCards=false" text><v-icon>mdi-close</v-icon></v-btn>
          <v-card-title>配送カード一覧</v-card-title>
        </v-card-actions>
        <v-card-text>
          <p>合計枚数：{{ deliveryCards.reduce((acc, card) => acc + card.count, 0) }}, 
            合計価格：{{ deliveryCards.reduce((acc, card) => acc + card.count*card.price, 0) }} 円</p>
          <v-data-table :headers="headersDeliveryCards" :items="deliveryCards" item-key="card_id"
            :items-per-page="-1" fixed-header show-select>
            <template v-slot:item.image="{item}">
              <v-img :src="item.image" class="ma-1" width="40px"></v-img>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
// import { ids } from 'webpack';
// import pdfFonts from '../assets/fonts/vfs_fonts';
import Api from '@/modules/api'
import store from '../store';


export default {
  name: 'AdminDelivery',
  data(){
    return {
      dates: [],
      selected: [],
      deliveryStatusForFilter: [0],
      headers: [
        {text: 'status',value:'delivery_status'},
        {text: 'create_ts',value:'create_ts'},
        {text: 'name',value:'name'}
        // {text: 'Email',value:'mail_address'},
      ],
      search: '',
      orders_headers: [
        {text:'カード',value:'image'},
        {text:'need',value:'count'},
        {text:'パック',value:'pack_number'},
        {text:'リスト番号',value:'card_list_number'},
        {text:'レア度',value:'card_rarity'},
        {text:'名前',value:'card_name'},
      ],
      dialog: false,
      dialogDeliveryCards: false,
      status: [
        {text: '配送準備', value:0},
        {text: '配送済み', value:1}
      ],
      deliveryStatus: '',
      deliveryDate: null,
      responsiblePerson: null,
      people: ['沢井宇宙'],
      deliveryCompany: null,
      companies: ['郵便'],
      trackingNumber: '',
      headersDeliveryCards: [
        {text:'card',value:'image'},
        {text:'need',value:'count'},
        {text:'opt',value:'options'},
        {text:'rare',value:'card_rarity'},
        {text:'pack',value:'pack_number'},
        {text:'list',value:'card_list_number'},
        {text:'name',value:'card_name'},
        {text:'return',align:'right',value:'return_point'},
        {text:'price',align:'right',value:'price'}
      ],
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    delivery_history: function(){
      const delivery_history = store.getters.delivery_history
      if(delivery_history == null) return []
      return delivery_history
    },
    filteredDeliveryHistory: function(){
      let range = this.dates
      if(range[0]>range[1]) [range[0],range[1]] = [range[1],range[0]]
      console.log("dates range: ",range)
      let filterd = this.delivery_history
      filterd = filterd.filter(history => this.deliveryStatusForFilter.includes(history.delivery_status))
      filterd = filterd.filter(history => {
        if(history.delivery_status == 1){
          return range[0] <= history.create_ts && history.create_ts <= range[1]
        }else{
          return true
        }
      })
      return filterd
    },
    deliveryCards: function(){
      const deliveryCardsAll = this.delivery_history.filter(history => history.delivery_status == 0).map(history => history.orders).flat()
      console.log(deliveryCardsAll)
      let deliveryCards = Object.values(deliveryCardsAll.reduce((acc, card) => {
        if (acc[card.card_id]) {
          acc[card.card_id].count++;
        } else {
          acc[card.card_id] = { ...card, count: 1 };
        }
        return acc;
      }, {}))
      deliveryCards.sort((a, b) => b.price - a.price)
      console.log(deliveryCards)
      // let deliveryCards = []
      // for(var i=0;i<deliveryHistory.length;i++){
      //   if(deliveryHistory[i].card_id.includes(deliveryCards.map(card=>card.card_id))){

      //   }
      // }
      return deliveryCards
    },
  },
  mounted: function(){
    const today = new Date().toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"});
    const now = new Date(today);
    console.log(now)
    const weekday = now.getDay();
    if (weekday === 1) {
      const start = `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`.replace(/\n|\r/g, '');
      this.dates = [start,start]
      // console.log(formatted);
    }else{
      const lastMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - weekday + 1);
      const start = `${lastMonday.getFullYear()}-${lastMonday.getMonth()+1}-${lastMonday.getDate()}`.replace(/\n|\r/g, '');
      const end = `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`.replace(/\n|\r/g, '');
      this.dates = [start,end]
      // console.log(formatted);
    }
  },
  methods: {
    test: function(){
      console.log(this.selected)
    },
    selectDelivery: function(){
      this.dialog = true
      this.deliveryStatus=this.selected[0].delivery_status
      this.deliveryDate = this.selected[0].delivery_date
      this.responsiblePerson = this.selected[0].responsible_person
      this.deliveryCompany = this.selected[0].delivery_company
      this.trackingNumber = this.selected[0].tracking_number
    },
    updateDeliveryHistoryWrap: function(){
      Api.putDeliveryHistory(
        this.selected[0].delivery_id,
        this.deliveryStatus,
        this.deliveryDate,
        this.responsiblePerson,
        this.deliveryCompany,
        this.trackingNumber
      )
    },
    fullWidth2HalfWidth: function(src) {
      src = src.replaceAll('−', '-');
      return src.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    },
    createPdfContents(){
      //fname,lname,post_code,prefectures,municipalities,address,building
      var delivery = arguments[0]

      if(delivery.length%2 == 1){
        delivery.push(JSON.parse(JSON.stringify(delivery[0])))
      }

      const start_x = 10
      const start_y = 10
      
      const width = 280
      const height = 190
      const padding = 10

      const diff_x = width+padding


      const post_code_height = 20
      const prefecture_height = 50
      const address_height = 70
      const building_height = 90
      const post_code_font = 17
      const shift_x = 20

      var content = []
      for(var i = 0;i < delivery.length; i+=2){
        content.push(
          { text: "〒"+this.fullWidth2HalfWidth(delivery[i].post_code.substr(0,3)+"-"+delivery[i].post_code.substr(3)),relativePosition: {x:shift_x, y:post_code_height},fontSize: post_code_font,pageBreak: (i!=0 && i%8 == 0) ? 'before' : null},
          { text: this.fullWidth2HalfWidth(delivery[i].prefectures+delivery[i].municipalities),relativePosition: {x:shift_x, y:prefecture_height},},
          { text: this.fullWidth2HalfWidth(delivery[i].address),relativePosition: {x:shift_x, y:address_height},},
          { text: this.fullWidth2HalfWidth(delivery[i].building),relativePosition: {x:shift_x, y:building_height},},
          { text: this.fullWidth2HalfWidth(delivery[i].last_name +" "+ delivery[i].first_name + " 様"),relativePosition: {x:shift_x, y:120},fontSize: 30},
          { image: require('../assets/logo/arcara.png'),relativePosition: {x:180, y:160},width:100},

          { text: this.fullWidth2HalfWidth("〒"+delivery[i+1].post_code.substr(0,3)+"-"+delivery[i+1].post_code.substr(3)),relativePosition: {x:shift_x+diff_x, y:post_code_height},fontSize: post_code_font},
          { text: this.fullWidth2HalfWidth(delivery[i+1].prefectures+delivery[i+1].municipalities),relativePosition: {x:shift_x+diff_x, y:prefecture_height},},
          { text: this.fullWidth2HalfWidth(delivery[i+1].address),relativePosition: {x:shift_x+diff_x, y:address_height},},
          { text: this.fullWidth2HalfWidth(delivery[i+1].building),relativePosition: {x:shift_x+diff_x, y:building_height},},
          { text: this.fullWidth2HalfWidth(delivery[i+1].last_name +" "+ delivery[i+1].first_name + " 様"),relativePosition: {x:shift_x+diff_x, y:120},fontSize: 30},
          { image: require('../assets/logo/arcara.png'),relativePosition: {x:180+diff_x, y:160},width:100},

          { canvas: [ 
              {type: 'rect', x:start_x, y:start_y, w:width, h:height},
              {type: 'rect', x:start_x+diff_x, y:start_y, w:width, h:height,pageBreak: (i!=0 && i%8 == 0) ? 'after' : null}
            ] 
          }
        )
      }      

      return content
    },
    printAll: function(){
      const fontDescriptors = {
        GenShin: {
          normal: "GenShinGothic-Normal-Sub.ttf"
        }
      }
      
      pdfMake.fonts = fontDescriptors;
      var delivery_history_list = []
      for(var dh of this.delivery_history){
        if(dh.delivery_status == 0){
          delivery_history_list.push(dh)
        }
      }

      console.log(delivery_history_list)

      const docDefinition = {
        defaultStyle: {
          font: 'GenShin',
          fontSize: 15
        },
        pageSize: 'A4',
        pageMargins: [ 0, 0, 0, 0 ],
        content: this.createPdfContents(delivery_history_list)
      };
      pdfMake.createPdf(docDefinition).download('example.pdf');
    }
  }
}
</script>