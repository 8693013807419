<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" clipped>
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title grey--text text--darken-2">
              Administration
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item v-for="nav_list in nav_lists" :key="nav_list.name" :to="nav_list.link">
            <v-list-item-icon>
              <v-icon>{{ nav_list.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <v-app-bar app color="black" dark clipped-left>
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center">Oripa Administrator</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon v-if="$store.getters.jwtToken!=null">mdi-account-edit-outline</v-icon>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { ApiGatewayManagementApi } from 'aws-sdk';
import Api from '@/modules/api'
import Utils from '@/modules/utils'

export default {
  name: 'App',
  data(){
    return {
      drawer: false,
      nav_lists: [
        {name: 'ユーザー管理', icon: 'mdi-account-circle-outline', link: 'adminUsers'},
        {name: 'オリパ作成', icon: 'mdi-gift-outline', link: 'createOripa'},
        // {name: 'オリパ管理', icon: 'mdi-gift-open-outline', link: 'adminOripa'},
        {name: 'オリパデータ', icon: 'mdi-chart-line', link: 'oripaData'},
        {name: 'カード在庫管理', icon: 'mdi-cards', link: 'adminCards'},
        {name: 'カード一覧', icon: 'mdi-cards-variant', link: 'cardStatisticsList'},
        {name: '配送管理', icon: 'mdi-truck-outline', link: 'adminDelivery'},
        {name: 'クーポン管理', icon: 'mdi-ticket', link: 'adminCoupon'},
      ],
    }
  },
  mounted: function(){
    if(this.$store.getters.jwtToken == null) Utils.getSession()
  },
  methods: {
    // getSession: function(){
    //   this.$cognito.isAuthenticated().then(result => {
    //     try{
    //       console.log(result)
    //       const jwtToken = result.idToken.jwtToken
    //       console.log("jwtToken: ",jwtToken)
    //       this.$store.commit('setJwtToken',jwtToken)
    //       Api.getOripaAll().then(res => {
    //         this.$store.commit('setOripas',res.data)
    //       })
    //     }catch(err){
    //       console.log(err)
    //     }
    //   })
    // }
  }
};
</script>
