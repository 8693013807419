import cognito from '../cognito'
import store from '../store'
import Api from './api'

export default {
  login(email,password){
    console.log("=====login=====")
    return new Promise((resolve,reject) => {
      cognito.signin(email,password).then(res => {
        const jwtToken = res.idToken.jwtToken
        console.log(jwtToken)
        store.commit('setJwtToken',jwtToken)
        Api.getOripaAll()
        Api.getCardsStock()
        Api.getOripasTemplate()
        Api.getDeliveryHistory()
        Api.getCoupon()
        Api.getCardMaster()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getSession: function(){
    console.log('=====get_session=====')
    return new Promise((resolve,reject) => {
      cognito.isAuthenticated().then(res => {
        const jwtToken = res.idToken.jwtToken
        console.log(jwtToken)
        store.commit('setJwtToken',jwtToken)
        Api.getOripaAll()
        Api.getCardsStock()
        Api.getOripasTemplate()
        Api.getDeliveryHistory()
        Api.getCoupon()
        Api.getCardMaster()
        Api.getCardsDelivery()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}