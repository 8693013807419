import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminUsers from '../views/AdminUsers.vue'
import AdminOripa from '../views/AdminOripa.vue'
import AdminCards from '../views/AdminCards.vue'
import AdminDelivery from '../views/AdminDelivery.vue'
import CreateOripa from '../views/CreateOripa.vue'
import Login from '../views/Login.vue'
import CardStatisticsList from '../views/CardStatisticsList.vue'
import CardStatistics from '../views/CardStatistics.vue'
import CardMaster from '../views/CardMaster.vue'
import AdminCoupon from '../views/AdminCoupon.vue'
import OripaData from '../views/OripaData.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/adminUsers',
    name: 'adminUsers',
    component: AdminUsers
  },
  {
    path: '/adminOripa',
    name: 'adminOripa',
    component: AdminOripa
  },
  {
    path: '/adminCards',
    name: 'adminCards',
    component: AdminCards
  },
  {
    path: '/adminDelivery',
    name: 'adminDelivery',
    component: AdminDelivery
  },
  {
    path: '/createOripa',
    name: 'createOripa',
    component: CreateOripa
  },
  {
    path: '/cardStatisticsList',
    name: 'cardStatisticsList',
    component: CardStatisticsList
  },
  {
    path: '/cardStatistics',
    name: 'cardStatistics',
    component: CardStatistics,
    props: true
  },
  {
    path: '/cardMaster',
    name: 'cardMaster',
    component: CardMaster,
  },
  {
    path: '/adminCoupon',
    name: 'adminCoupon',
    component: AdminCoupon,
  },
  {
    path: '/oripaData',
    name: 'oripaData',
    component: OripaData,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
