<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-card-title>
              Create Oripa
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogTemplate=true"><v-icon>mdi-auto-fix</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-card-subtitle>
              S: {{ prizeCards.filter(item=>item["prize"]=='S').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
              A: {{ prizeCards.filter(item=>item["prize"]=='A').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
              B: {{ prizeCards.filter(item=>item["prize"]=='B').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
              C: {{ blankCards.filter(item=>item["prize"]=='C').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
              LAST: {{ prizeCards.filter(item=>item["prize"]=='LAST').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }}
            </v-card-subtitle>
            <v-row>
              <v-col><v-text-field v-model="oripa_name" dense hide-details filled label="オリパ名"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="subtitle" dense hide-details outlined label="サブタイトル"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><v-text-field v-model="all_lot" dense hide-details filled label="全口数" suffix="【口】"></v-text-field></v-col>
              <v-col cols="6"><v-text-field v-model="point" dense hide-details filled label="1ロット" suffix="【P】"></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table ref="tableBlankCards" :headers="headersBlankCard" :items="blankCards"
                  disable-pagination hide-default-footer fixed-header height="200">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>はずれ</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-btn color="error" @click="clearBlankCards" icon><v-icon>mdi-delete</v-icon></v-btn>
                      <v-btn color="success" @click="sortBlank" icon><v-icon>mdi-sort-descending</v-icon></v-btn>
                      <v-btn color="primary" @click="addRate">追加</v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.prize="props">
                    <v-edit-dialog>
                      {{ props.item.prize }}
                      <template v-slot:input>
                        <v-select v-model="props.item.prize" :items="blankPrizes" single-line></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.return_point="props">
                    <v-edit-dialog>
                      {{ props.item.return_point }}
                      <template v-slot:input>
                        <v-select v-model="props.item.return_point" :items="returnPoints" single-line></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.number="props">
                    <v-edit-dialog>
                      {{ props.item.number }}
                      <template v-slot:input>
                        <v-text-field v-model="props.item.number" single-line type="number"></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.lot="props">
                    <v-edit-dialog>
                      {{ props.item.lot }}
                      <template v-slot:input>
                        <v-text-field v-model="props.item.lot" single-line type="number"></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.actions="{item}">
                    <v-icon small @click="deleteBlankCard(item)">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>Simulation</v-card-title>
                  <v-card-text>
                    <v-text-field v-model="total_number" readonly suffix="【枚】" label="カード合計数"></v-text-field>
                    <v-text-field v-model="cvp_return_rate" readonly suffix="【%】" label="ポイント還元率の損益分岐点"></v-text-field>
                    <v-text-field v-model="return_rate" readonly suffix="【%】" label="高額以外のカードのポイント還元率"></v-text-field>
                    <v-text-field v-model="need_money" readonly suffix="【円】" label="買い占めるのに必要な金額"></v-text-field>
                    <v-text-field v-model="profit" readonly suffix="【円】" label="利益"></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8">
            <v-data-table ref="tablePrizeCards" :headers="headersPrizeCard" :items="prizeCards" item-key="card_id"
              disable-pagination hide-default-footer fixed-header height="450">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>当たりカード</v-toolbar-title>
                  <v-divider class="ml-2" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="clearPrizeCards" icon><v-icon>mdi-delete</v-icon></v-btn>
                  <v-btn color="success" @click="sortPrize" icon><v-icon>mdi-sort-descending</v-icon></v-btn>
                  <v-btn color="primary" @click="setSelectedAddCards();dialogAddCard=true">追加</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.actionPrize="props">
                <v-menu offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn v-bind="attrs" v-on="on" icon><v-icon small>mdi-sort-ascending</v-icon></v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-btn @click="actionPrizeA(props.index)">以降Aにする</v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn @click="actionPrizeB(props.index)">以降Bにする</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.prize="props">
                <v-edit-dialog>
                  {{ props.item.prize }}
                  <template v-slot:input>
                    <v-select v-model="props.item.prize" :items="prizePrizes" single-line></v-select>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.image="{item}">
                <v-img :src="item.image" class="ma-1" width="40px"></v-img>
              </template>
              <template v-slot:item.lot="props">
                <v-edit-dialog>
                  {{ props.item.lot }}
                  <template v-slot:input>
                    <v-text-field v-model="props.item.lot" single-line type="number"></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.rush_price="{item}">
                <span :class="{'red lighten-2': item.rush_price > item.price}">{{ item.rush_price }}</span>
              </template>
              <template v-slot:item.stock_info="{item}">
                {{ item.sum_stock }}[{{ item.rush_stock }}]
              </template>
              <template v-slot:item.actions="{item}">
                <v-icon small @click="deletePrizeCard(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
            <v-divider></v-divider>
            <v-combobox v-model="hashtags" :items="hashtagItems" chips clearable label="hashtags" multiple prepend-icon="mdi-pound-box-outline" solo class="mt-5">
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="removeHashtag(item)" color="#bf9507" class="white--text">
                  <strong>{{ item }}</strong>&nbsp;
                </v-chip>
              </template>
            </v-combobox>
            <v-textarea v-model="detail" outlined label="オリパ詳細（無くてもいい,100文字以内）" counter rows="1" auto-grow prepend-icon="mdi-details"></v-textarea>
            <v-divider class="mb-5"></v-divider>
            <v-row>
              <v-col><v-switch v-model="publish" :label="`publish: ${publish.toString()}`" hide-details></v-switch></v-col>
              <v-col><v-select v-model="oripaType" :items="oripaTypes" item-text="text" item-value="value" outlined hide-details label="条件"></v-select></v-col>
              <v-col><v-text-field v-model="max_draw" outlined type="number" label="最大ガチャ数" hint="1人N回限定のときに有効"></v-text-field></v-col>
              <v-col><v-select v-model="design" :items="designs" outlined hide-details label="design"></v-select></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu ref="menuDateOpen" v-model="menuDateOpen" :close-on-content-click="false" :return-value.sync="dateOpen"
                transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs}">
                    <v-text-field v-model="dateOpen" label="公開日" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                  </template>
                  <v-date-picker v-model="dateOpen" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDateOpen=false">Cansel</v-btn>
                    <v-btn text color="primary" @click="$refs.menuDateOpen.save(dateOpen)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-select v-model="timeOpen" :items="times" hide-details></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu ref="menuDateSale" v-model="menuDateSale" :close-on-content-click="false" :return-value.sync="dateSale"
                transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs}">
                    <v-text-field v-model="dateSale" label="販売日" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                  </template>
                  <v-date-picker v-model="dateSale" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDateSale=false">Cansel</v-btn>
                    <v-btn text color="primary" @click="$refs.menuDateSale.save(dateSale)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-select v-model="timeSale" :items="times" hide-details></v-select>
              </v-col>
            </v-row>
            <v-btn block class="mt-5" color="primary" @click="postOripaTemplate">テンプレートに保存する</v-btn>
            <v-btn block class="mt-5" color="error" @click="createOripa">オリパを作成する</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="mt-5">
      <v-col>
        <v-card>
          <v-card-title>オリパ一覧</v-card-title>
          <v-card-text>
            <v-data-table v-model="selectedOripa" :items="oripas" :headers="headersOripa" item-key="oripa_id"
              :single-select="true" show-select :items-per-page="-1" hide-default-header hide-default-footer>
              <template v-slot:item.id="{item}">No.{{ item.oripa_id }}</template>
              <template v-slot:item.publish="{item}">
                <v-chip v-if="now<item.sale_ts||item.publish!=1" color="orange" class="white--text">未販売</v-chip>
                <v-chip v-else-if="getRestLot(item.oripa_stock)==0" color="red" class="white--text">SOLDOUT</v-chip>
                <v-chip v-else color="green" class="white--text">販売中</v-chip>
              </template>
              <template v-slot:item.name="{item}">【{{item.price}}pt】{{ item.oripa_name }}</template>
              <template v-slot:item.content="{item}">
                <v-chip label outlined>{{ getRestLot(item.oripa_stock) }} / {{ getLot(item.oripa_stock) }}</v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card v-if="selectedOripa.length>0">
          <v-card-title>
            No.{{selectedOripa[0].oripa_id}} 【{{selectedOripa[0].price}}pt】{{ selectedOripa[0].oripa_name }}
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mr-5" @click="applyTemplate(selectedOripa[0])">Apply</v-btn>
            <v-btn color="error" @click="deleteOripa(selectedOripa[0].oripa_id)">削除</v-btn>
          </v-card-title>
          <v-card-subtitle class="pb-0">{{ selectedOripa[0].subtitle }}</v-card-subtitle>
          <v-card-subtitle>
            S: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='S').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='S').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
            A: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='A').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='A').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
            B: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='B').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='B').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
            C: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='C').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='C').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
            LAST: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='LAST').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='LAST').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }}
          </v-card-subtitle>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>Update</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-combobox v-model="hashtagsUpdate" :items="hashtagItems" chips clearable label="hashtags" multiple prepend-icon="mdi-pound-box-outline" solo>
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="removeHashtagUpdate(item)" color="#bf9507" class="white--text">
                        <strong>{{ item }}</strong>&nbsp;
                      </v-chip>
                    </template>
                  </v-combobox>
                  <v-textarea v-model="detailUpdate" outlined label="オリパ詳細（無くてもいい,100文字以内）" counter rows="1" auto-grow prepend-icon="mdi-details"></v-textarea>
                  <v-divider class="mb-5"></v-divider>
                  <v-row>
                    <v-col><v-switch v-model="publishUpdate" :label="`publish: ${publishUpdate.toString()}`" hide-details></v-switch></v-col>
                    <v-col><v-select v-model="oripaTypeUpdate" :items="oripaTypes" item-text="text" item-value="value" outlined hide-details label="条件"></v-select></v-col>
                    <v-col><v-text-field v-model="maxDrawUpdate" outlined type="number" hide-details label="最大ガチャ数"></v-text-field></v-col>
                    <v-col><v-select v-model="designUpdate" :items="designs" outlined hide-details label="design" hide-details></v-select></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-menu ref="menuDateOpenUpdate" v-model="menuDateOpenUpdate" :close-on-content-click="false" :return-value.sync="dateOpenUpdate"
                      transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs}">
                          <v-text-field v-model="dateOpenUpdate" label="公開日" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="dateOpenUpdate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuDateOpenUpdate=false">Cansel</v-btn>
                          <v-btn text color="primary" @click="$refs.menuDateOpenUpdate.save(dateOpenUpdate)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-select v-model="timeOpenUpdate" :items="times" hide-details></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-menu ref="menuDateSaleUpdate" v-model="menuDateSaleUpdate" :close-on-content-click="false" :return-value.sync="dateSaleUpdate"
                      transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs}">
                          <v-text-field v-model="dateSaleUpdate" label="販売日" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateSaleUpdate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuDateSaleUpdate=false">Cansel</v-btn>
                          <v-btn text color="primary" @click="$refs.menuDateSaleUpdate.save(dateSaleUpdate)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-select v-model="timeSaleUpdate" :items="times"></v-select>
                    </v-col>
                  </v-row>
                  <v-btn block color="error" @click="updateOripa(selectedOripa[0].oripa_id)">オリパ情報を変更</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table :items="selectedOripa[0].oripa_stock" :headers="oripa_stock_headers" :items-per-page="-1" hide-default-footer>
              <template v-slot:item.image="{ item }" >
                <v-img :src="item.image" class="ma-1" width="50px"></v-img>
              </template>
              <template v-slot:item.return_point="{item}">
                <p v-if="item.number!=null" class="mb-0">{{ item.number }} * {{ item.return_point }}pt</p>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-dialog v-model="dialogAddCard">
      <v-card>
        <v-card-actions>
          <v-btn text @click="dialogAddCard=false"><v-icon>mdi-arrow-left</v-icon>戻る</v-btn>
        </v-card-actions>
        <v-card-text>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="addCards">add cards({{selectedAddCards.length}})</v-btn>
          </v-card-actions> -->
          <v-row>
            <v-col cols="4">
              <v-card class="mt-3 mb-3">
                <v-container>
                  <v-row>
                    <v-col><v-combobox dense solo chips v-model="filtering.selectedRarity" :items="filtering.rarity" multiple label="レアリティ" hide-details></v-combobox></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col><v-text-field v-model="filtering.return_point_l" dense solo type="number" single-line hide-details label="還元P最小値"></v-text-field></v-col>
                    <v-col><v-text-field v-model="filtering.return_point_u" dense solo type="number" single-line hide-details label="還元P最大値"></v-text-field></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col><v-text-field v-model="filtering.price_l" dense solo type="number" single-line hide-details label="相場最小値"></v-text-field></v-col>
                    <v-col><v-text-field v-model="filtering.price_u" dense solo type="number" single-line hide-details label="相場最大値"></v-text-field></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-card-actions>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn @click="clearFilter">クリア</v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-btn color="cyan" class="mt-10" block @click="addCards">add cards({{selectedAddCards.length}})</v-btn>
            </v-col>
            <v-col cols="8">
              <v-data-table ref="tableAddCards" v-model="selectedAddCards" :headers="headersAddCard" :items="filteredCards" item-key="card_id"
                :sort-by="['price']" :sort-desc="[true]" :search="search" show-select disable-pagination hide-default-footer fixed-header height="800">
                <template v-slot:item.image="{item}">
                  <v-img :src="item.image" class="ma-1" width="40px"></v-img>
                </template>
                <template v-slot:item.stock_info="{item}">
                  {{ item.sum_stock }}[{{ item.rush_stock }}]
                </template>
                <template v-slot:item.rush_price="{item}">
                  <span :class="{'red lighten-2': item.rush_price > item.price}">{{ item.rush_price }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTemplate">
      <v-card>
        <v-card-actions><v-btn @click="dialogTemplate=false">戻る</v-btn></v-card-actions>
        <v-card-title>オリパテンプレート</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <v-data-table v-model="selectedOripaTemplate" :items="oripasTemplate" :headers="headersOripaTemplate" item-key="oripa_template_id"
                    :single-select="true" show-select :items-per-page="-1" hide-default-header hide-default-footer>
                    <template v-slot:item.id="{item}">No.{{ item.oripa_template_id }}</template>
                    <template v-slot:item.name="{item}">【{{item.price}}pt】{{ item.oripa_name }}</template>
                    <template v-slot:item.content="{item}">
                      <v-chip label outlined>{{ getRestLot(item.stock) }} / {{ getLot(item.stock) }}</v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card v-if="selectedOripaTemplate.length>0">
                <v-card-title>
                  No.{{selectedOripaTemplate[0].oripa_template_id}} 【{{selectedOripaTemplate[0].price}}pt】{{ selectedOripaTemplate[0].oripa_name }}
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="deleteOripaTemplate(selectedOripaTemplate[0].oripa_template_id)">削除</v-btn>
                </v-card-title>
                <v-card-subtitle class="pb-0">{{ selectedOripaTemplate[0].subtitle }}</v-card-subtitle>
                <v-card-subtitle>
                  S: {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='S').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='S').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
                  A: {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='A').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='A').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
                  B: {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='B').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='B').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
                  C: {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='C').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='C').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
                  LAST: {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='LAST').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripaTemplate[0].stock.filter(item=>item["prize"]=='LAST').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }}
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col><v-text-field outlined readonly label="design" :value="`${selectedOripaTemplate[0].design}`" hide-details></v-text-field></v-col>
                    <v-col><v-text-field outlined readonly label="条件" :value="`${selectedOripaTemplate[0].oripa_type}`" hide-details></v-text-field></v-col>
                    <v-col><v-text-field outlined readonly label="ガチャ最大数" :value="`${selectedOripaTemplate[0].max_draw}`" hide-details></v-text-field></v-col>
                  </v-row>
                  
                  <v-chip-group column>
                    <v-chip v-for="hashtag in selectedOripaTemplate[0].hashtags?selectedOripaTemplate[0].hashtags.split(','):[]" :key="hashtag" color="#bf9507" class="white--text">{{ hashtag }}</v-chip>
                  </v-chip-group>
                  <v-textarea class="mt-3" outlined readonly label="オリパ詳細" :value="`${selectedOripaTemplate[0].detail}`" counter rows="1" auto-grow prepend-icon="mdi-details" hide-details></v-textarea>
                </v-card-text>
                <v-card-actions><v-btn block color="primary" @click="applyTemplate(selectedOripaTemplate[0]);dialogTemplate=false">テンプレートを適用する</v-btn></v-card-actions>
                <v-card-text>
                  <v-data-table :items="selectedOripaTemplate[0].stock" :headers="oripa_stock_headers" :items-per-page="-1" hide-default-footer>
                    <template v-slot:item.image="{ item }" >
                      <v-img :src="item.image" class="ma-1" width="50px"></v-img>
                    </template>
                    <template v-slot:item.return_point="{item}">
                      <p v-if="item.number!=null" class="mb-0">{{ item.number }} * {{ item.return_point }}pt</p>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import store from '../store'
import Api from '@/modules/api'

export default {
  name: 'CreateOripa',
  data: function(){
    const hours = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
    const mins = ['00','15','30','45']
    let times = []
    for(let i=0;i<hours.length;i++){
      for(let j=0;j<mins.length;j++){
        times.push(hours[i]+':'+mins[j])
      }
    }
    const today = new Date().toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"});
    const nowAll = new Date(today)
    const now = this.formatDate(nowAll, 'yyyy-MM-ddTHH:mm:ss');
    console.log(now)
    return{
      now: now,
      oripa_name: null,
      subtitle: null,
      all_lot: null,
      point: null,
      dialogTemplate: false,
      dialogAddCard: false,
      headersBlankCard: [
        {text:'賞',value:'prize'},
        {text:'枚',value:'number'},
        {text:'還元pt',value:'return_point'},
        {text:'排出数',value:'lot'},
        {text:'', value: 'actions', sortable: false },
      ],
      blankPrizes: ['C'],
      prizePrizes: ['S','A','B','LAST','C'],
      returnPoints: [80,120,360,580],
      blankCards: [
        {prize:'C',return_point:80,number:1,lot:0}
      ],
      headersPrizeCard: [
        {value:'actionPrize',sortable:false},
        {value:'prize'},
        {align:'right',value:'lot'},
        {value:'image'},
        {value:'card_rarity'},
        {value:'card_name'},
        {text:'還元P',align:'right',value:'return_point'},
        {text:'相場',align:'right',value:'price'},
        {text:'相場rush',align:'right',value:'rush_price'},
        {text:'在庫[rush]',value:'stock_info'},
        {value: 'actions', sortable: false },
      ],
      prizeCards: [],
      headersAddCard: [
        {value:'image'},
        {value:'options'},
        {value:'card_rarity'},
        {value:'card_name'},
        {text:'還元P',align:'right',value:'return_point'},
        {text:'相場',align:'right',value:'price'},
        {text:'相場rush',align:'right',value:'rush_price'},
        {text:'在庫[rush]',value:'stock_info'},
        // {text:'在庫rush',align:'right',value:'rush_stock'}
      ],
      selectedAddCards: [],
      search: '',
      filtering: {
        rarity: ['-','R','RR','RRR','S','SR','SSR','SAR','CSR','AR','HR','UR'],
        selectedRarity: [],
        return_point_l: null,
        return_point_u: null,
        price_l: null,
        price_u: null,
      },
      oripa_stock_headers: [
        {text:'景品',value:'prize'},
        {text:'カード',value:'image'},
        {text:'名前',value:'card_name'},
        {text:'還元P',value:'return_point'},
        {text:'残り',value:'rest_lot'},
        {text:'全枠',value:'lot'}
      ],
      selectedOripa: [],
      headersOripa: [
        {value:'id'},
        {value:'publish'},
        {value:'name'},
        {value:'content'}
      ],
      selectedOripaTemplate: [],
      headersOripaTemplate: [
        {value:'id'},
        {value:'name'},
        {value:'content'}
      ],
      menuDateOpen: false,
      dateOpen: (new Date((new Date()).getTime() + 7 * 24 * 60 * 60 * 1000 + (new Date()).getTimezoneOffset() * 60 * 1000)).toISOString().substr(0, 10),
      timeOpen: '00:00',
      menuDateSale: false,
      dateSale: (new Date((new Date()).getTime() + 7 * 24 * 60 * 60 * 1000 + (new Date()).getTimezoneOffset() * 60 * 1000)).toISOString().substr(0, 10),
      timeSale: '00:00',
      times: times,
      publish: 1,
      design: 1,
      oripaType: 0,
      max_draw: 1000,
      oripaTypes: [
        {text:'条件なし',value:0},
        {text:'1人N回限定',value:1}
      ],
      hashtags: [],
      hashtagItems: ['50%アド!!','50%SR以上確定!!','1/3で360pt以上!!','マイルド'],
      detail: '',

      menuDateOpenUpdate: false,
      dateOpenUpdate: (new Date((new Date()).getTime() + 7 * 24 * 60 * 60 * 1000 + (new Date()).getTimezoneOffset() * 60 * 1000)).toISOString().substr(0, 10),
      timeOpenUpdate: '00:00',
      menuDateSaleUpdate: false,
      dateSaleUpdate: (new Date((new Date()).getTime() + 7 * 24 * 60 * 60 * 1000 + (new Date()).getTimezoneOffset() * 60 * 1000)).toISOString().substr(0, 10),
      timeSaleUpdate: '00:00',
      publishUpdate: 1,
      designUpdate: 1,
      oripaTypeUpdate: 0,
      designs: [1,2,3,4,5,6,7,8,9],
      hashtagsUpdate: [],
      detailUpdate: ''
    }
  },
  components: {
    draggable: draggable
  },
  computed: {
    oripas: function(){
      const oripas = store.getters.oripas
      if(oripas == null) return []
      return oripas
    },
    oripasTemplate: function(){
      const oripasTemplate = store.getters.oripasTemplate
      if(oripasTemplate == null) return []
      return oripasTemplate
    },
    allCards: function(){
      const allCards = store.getters.cardsStock
      if(allCards == null) return []
      return allCards
    },
    filteredCards: function(){
      let filtered = this.allCards
      if(this.filtering.selectedRarity.length != 0) filtered = filtered.filter(card => this.filtering.selectedRarity.includes(card.card_rarity))
      if(this.filtering.return_point_l != null) filtered = filtered.filter(card => this.filtering.return_point_l <= card.return_point)
      if(this.filtering.return_point_u) filtered = filtered.filter(card => card.return_point <= this.filtering.return_point_u)
      if(this.filtering.price_l) filtered = filtered.filter(card => this.filtering.price_l <= card.price)
      if(this.filtering.price_u) filtered = filtered.filter(card => card.price <= this.filtering.price_u)
      return filtered
    },
    total_number: function(){
      let total_number = 0
      total_number += this.blankCards.map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0)
      total_number += this.prizeCards.filter(item=>item["prize"]!='LAST').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0)
      return total_number
    },
    totalPrizePrice: function(){
      let totalPrizePrice = 0
      for(let i=0;i<this.prizeCards.length;i++){
        totalPrizePrice += this.prizeCards[i].price*this.prizeCards[i].lot
      }
      return totalPrizePrice
    },
    cvp_return_rate: function(){
      return 100*(1-this.totalPrizePrice/(this.point*this.all_lot))
    },
    return_rate: function(){
      let return_rate = 0
      for(let i=0;i<this.blankCards.length;i++){
        if(this.blankCards[i].return_point == 580){
          return_rate += (this.blankCards[i].return_point+200)*this.blankCards[i].number*(this.blankCards[i].lot/this.all_lot)/this.point
        }else if(this.blankCards[i].return_point == 360){
          return_rate += (this.blankCards[i].return_point+20)*this.blankCards[i].number*(this.blankCards[i].lot/this.all_lot)/this.point
        }else{
          return_rate += this.blankCards[i].return_point*this.blankCards[i].number*(this.blankCards[i].lot/this.all_lot)/this.point
        }
      }
      return return_rate
    },
    need_money: function(){
      return Math.trunc(this.all_lot*this.point*(1-this.return_rate))
    },
    profit: function(){
      return Math.trunc(this.need_money*0.964 - this.totalPrizePrice)
    },
  },
  watch: {
    selectedOripa: function(next,prev){
      if(next.length>0){
        this.dateOpenUpdate = next[0].open_ts.split('T')[0]
        this.timeOpenUpdate = next[0].open_ts.split('T')[1].substring(0,5)
        this.dateSaleUpdate = next[0].sale_ts.split('T')[0]
        this.timeSaleUpdate = next[0].sale_ts.split('T')[1].substring(0,5)
        this.publishUpdate = next[0].publish
        this.designUpdate = next[0].design
        this.hashtagsUpdate = next[0].hashtags ? next[0].hashtags.split(',') : []
        this.detailUpdate = next[0].detail
        this.oripaTypeUpdate = next[0].oripa_type
        this.maxDrawUpdate = next[0].max_draw
      }
    }
  },
  methods: {
    onMove(event) {
      const draggedIndex = event.draggedContext.index;
      const relatedIndex = event.relatedContext.index;
      if (draggedIndex !== relatedIndex) {
        const itemToMove = this.items[draggedIndex];
        this.items.splice(draggedIndex, 1);
        this.items.splice(relatedIndex, 0, itemToMove);
      }
    },
    onEnd() {
      // ドラッグアンドドロップが終了した後に何かする場合は、ここに処理を追加できます。
      console.log("End")
    },
    addRate: function(){
      this.blankCards.push({prize:'C',return_point:null,number:1,lot:0})
    },
    clearBlankCards: function(){
      this.blankCards.splice(0,this.blankCards.length)
      this.blankCards = [{prize:'C',return_point:80,number:1,lot:0}]
    },
    clearFilter: function(){
      this.filtering = {
        rarity: ['-','R','RR','RRR','SR','HR','UR'],
        selectedRarity: [],
        return_point_l: null,
        return_point_u: null,
        price_l: null,
        price_u: null,
      }
    },
    actionPrizeA: function(index){
      for (let i = index; i < this.prizeCards.length; i++) {
        if (this.prizeCards[i].hasOwnProperty('prize')) {
          this.prizeCards[i].prize = 'A';
        }
      }
    },
    actionPrizeB: function(index){
      for (let i = index; i < this.prizeCards.length; i++) {
        if (this.prizeCards[i].hasOwnProperty('prize')) {
          this.prizeCards[i].prize = 'B';
        }
      }
    },
    setSelectedAddCards: function(){
      this.selectedAddCards = this.prizeCards
    },
    addCards: function(){
      for(let i=0;i<this.selectedAddCards.length; i++){
        if(!this.prizeCards.includes(this.selectedAddCards[i])){
          this.$set(this.selectedAddCards[i],'lot',1)
          this.$set(this.selectedAddCards[i],'prize','A')
          this.prizeCards.push(this.selectedAddCards[i])
        }
      }
      this.dialogAddCard = false
    },
    clearPrizeCards: function(){
      this.prizeCards.splice(0,this.prizeCards.length)
    },
    sortPrize: function(){
      this.prizeCards.sort(function(a,b){return (a.price > b.price) ? -1 : 1;})
    },
    sortBlank: function(){
      this.blankCards.sort(function(a,b){return (a.return_point > b.return_point) ? -1 : 1;})
    },
    deleteBlankCard: function(item){
      this.blankCards.splice(this.blankCards.indexOf(item),1)
    },
    deletePrizeCard: function(item){
      this.prizeCards.splice(this.prizeCards.indexOf(item),1)
    },
    postOripaTemplate: function(){
      if(this.all_lot == this.total_number){
        Api.postOripaTemplate(this.oripa_name,this.subtitle,this.all_lot,this.point,this.prizeCards,this.blankCards,this.publish,this.design,this.hashtags.join(','),this.detail,this.oripaType,this.max_draw)
      }
    },
    createOripa: function(){
      // if(this.all_lot == this.total_number && this.profit > 50000 && this.dateOpen+' '+this.timeOpen <=this.dateSale+' '+this.timeSale){
      //   Api.postOripaAll(this.oripa_name,this.all_lot,this.point,this.prizeCards,this.blankCards,this.dateOpen,this.timeOpen,this.dateSale,this.timeSale)
      // }
      if(this.all_lot == this.total_number){
        Api.postOripaAll(this.oripa_name,this.subtitle,this.all_lot,this.point,this.prizeCards,this.blankCards,this.dateOpen,this.timeOpen,this.dateSale,this.timeSale,this.publish,this.design,this.hashtags.join(','),this.detail,this.oripaType,this.max_draw)
      }
    },
    deleteOripaTemplate: function(oripa_template_id){
      Api.deleteOripaTemplate(oripa_template_id).finally(()=>{
        this.selectedOripaTemplate.length = 0
      })
    },
    deleteOripa: function(oripa_id){
      Api.deleteOripa(oripa_id).finally(()=>{
        this.selectedOripa = []
      })
    },
    applyTemplate: function(oripa){
      this.prizeCards.splice(0,this.prizeCards.length)
      this.blankCards.splice(0,this.blankCards.length)
      this.allCards.map(card => card.prize = null)
      this.oripa_name = oripa.oripa_name
      this.subtitle = oripa.subtitle
      this.all_lot = oripa.all_lot
      this.point = oripa.price
      this.design = oripa.design
      this.hashtags = oripa.hashtags ? oripa.hashtags.split(',') : []
      this.detail = oripa.detail
      this.oripaType = oripa.oripa_type
      this.max_draw = oripa.max_draw
      const stocks = oripa.stock ? oripa.stock : oripa.oripa_stock
      let prize_cards = []
      let blank_cards = []
      for(let i=0;i<stocks.length;i++){
        if(['S','A','B','LAST'].includes(stocks[i].prize)){
          stocks[i]["return_point"] = stocks[i]["cm.return_point"]
          prize_cards.push(stocks[i])
        }else if(['C'].includes(stocks[i].prize)){
          blank_cards.push(stocks[i])
        }
      }
      const mergedPrizeCards = this.allCards.map(obj1 => {
        const obj2 = prize_cards.find(obj2 => obj2.card_id === obj1.card_id)
        return {...obj1, ...obj2}
      }).filter(obj => obj.prize)
      this.prizeCards = mergedPrizeCards
      this.blankCards = blank_cards
      this.sortPrize()
      this.sortBlank()
    },
    getRestLot: function(stock){
      return stock.filter(item=>item["prize"]!='LAST').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0)
    },
    getLot: function(stock){
      return stock.filter(item=>item["prize"]!='LAST').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0)
    },
    updateOripa: function(oripa_id){
      Api.putOripa(oripa_id,this.dateOpenUpdate,this.timeOpenUpdate,this.dateSaleUpdate,this.timeSaleUpdate,this.publishUpdate,this.designUpdate,this.hashtagsUpdate.join(','),this.detailUpdate,this.oripaTypeUpdate)
    },
    formatDate (date, format) {
      format = format.replace(/yyyy/g, date.getFullYear());
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
      format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
      format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
      return format;
    },
    removeHashtag (item) {
      this.hashtags.splice(this.hashtags.indexOf(item), 1)
    },
    removeHashtagUpdate (item) {
      this.hashtagsUpdate.splice(this.hashtagsUpdate.indexOf(item), 1)
    },
  },
}
</script>
