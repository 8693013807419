<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Pokemon Card Stock Database</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">還元Point</th>
                        <th class="text-left">カード種</th>
                        <th class="text-left">合計実在庫数</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in stockNumber" :key="item.return_point">
                        <td>{{ item.return_point }}</td>
                        <td>{{ item.species }}</td>
                        <td>{{ item.actual_stock }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-container>
                <v-row>
                  <v-col><v-combobox dense solo chips v-model="filtering.selectedRarity" :items="filtering.rarity" multiple label="レアリティ" hide-details></v-combobox></v-col>
                </v-row>
                <v-row dense>
                  <v-col><v-text-field v-model="filtering.return_point_l" dense solo type="number" single-line hide-details label="還元P最小値"></v-text-field></v-col>
                  <v-col><v-text-field v-model="filtering.return_point_u" dense solo type="number" single-line hide-details label="還元P最大値"></v-text-field></v-col>
                </v-row>
                <v-row dense>
                  <v-col><v-text-field v-model="filtering.price_l" dense solo type="number" single-line hide-details label="相場最小値"></v-text-field></v-col>
                  <v-col><v-text-field v-model="filtering.price_u" dense solo type="number" single-line hide-details label="相場最大値"></v-text-field></v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-card-actions>
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                      <v-spacer></v-spacer>
                      <v-btn @click="clearFilter">クリア</v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-title>
        Pokemon Card Stock Database
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title> -->
      <v-card-text>
        <v-data-table
          :headers="headers" :items="filteredCards" hide-default-header
          multi-sort :sort-by="[]" :sort-desc="[]" :items-per-page="10">
          <template v-slot:item.image="{ item }" >
            <v-img :src="item.image" :alt="item.card_id" class="ma-1" width="30px"></v-img>
          </template>
          <template v-slot:item.id="{item}">
            【{{ item.card_rarity }}】{{ item.card_list_number }} {{ item.pack_number }}
          </template>
          <template v-slot:item.name="{item}">
            【{{ item.options }}】{{ item.card_name }}
          </template>
          <template v-slot:item.point-price="{item}">
            {{ item.return_point }}pt / {{ item.price }}円
          </template>
          <template v-slot:item.edit="{ item }">
            <v-icon small @click="editItem(item)">mdi-pencil-outline</v-icon>
            <v-dialog v-model="dialog" max-width="700px" :retain-focus="false">
              <v-card>
                <v-card-title>【{{editedItem.options}}】{{ editedItem.card_name }}</v-card-title>
                <v-card-subtitle>【{{ editedItem.card_rarity }}】{{ editedItem.card_list_number }} {{ editedItem.pack_number }}</v-card-subtitle>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-img :src="editedItem.image" class="ma-2" max-width="250px"></v-img>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="editedItem.price" color="black" label="相場価格" suffix="円"></v-text-field>
                        <v-text-field v-model="editedItem.return_point" color="black" label="還元Point" suffix="P"></v-text-field>
                        <!-- <v-text-field filled v-model="editedItem.standard_stock_number" label="在庫基準数" readonly></v-text-field> -->
                        <v-text-field filled readonly v-model="editedItem.future_delivery_stock_number" label="潜在必要数"></v-text-field>
                        <!-- <v-text-field filled v-model="editedItem.actual_stock_number" label="実在庫数" readonly></v-text-field> -->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">閉じる</v-btn>
                  <v-btn color="blue darken-1" text @click="save">保存</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Api from '@/modules/api'
import store from '../store';

export default {
  name: 'AdminCards',
  data(){
    return{
      cardsStock: [],
      search: '',
      // headers: [
      //   {text:'カード',value:'image',sortable: false},
      //   {text:'パック',value:'pack_number',sortable: false},
      //   {text:'レア度',value:'card_rarity',sortable: false},
      //   {text:'名前',value:'card_name',sortable: false},
      //   {text:'還元P',value:'return_point'},
      //   {text:'相場',value:'price'},
      //   {text:'在庫基準数',value:'standard_stock_number'},
      //   {text:'潜在必要数',value:'future_delivery_stock_number'},
      //   {text:'実在庫数',value:'actual_stock_number'},
      //   {text:'編集', value: 'edit', sortable: false },
      // ],
      headers: [
        {text:'カード',value:'image',sortable: false},
        {text:'ID', value:'id',sortable: false},
        {text:'名前', value:'name', sortable: false},
        {text:'価格',value:'point-price',sortable: false},
        {text:'編集', value: 'edit', sortable: false },
      ],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        image: '',
        pack_number: '',
        card_list_number: '',
        card_rarity: '',
        card_name: '',
        return_point: '',
        price: '',
        standard_stock_number: 0,
        future_delivery_stock_number: 0,
        actual_stock_number: 0,
      },
      filtering: {
        rarity: ['-','R','RR','RRR','S','SR','SSR','SAR','CSR','AR','HR','UR'],
        selectedRarity: [],
        return_point_l: null,
        return_point_u: null,
        price_l: null,
        price_u: null,
      },
    }
  },
  computed: {
    allCards: function(){
      const cardsStock = store.getters.cardsStock
      if(cardsStock == null) return []
      return cardsStock
    },
    stockNumber: function(){
      const cardsStock = store.getters.cardsStock
      if(cardsStock == null) return []
      let stockNumber = [
        {return_point:80 ,species:0, actual_stock:0},
        {return_point:120 ,species:0, actual_stock:0},
        {return_point:380 ,species:0, actual_stock:0},
        {return_point:680 ,species:0, actual_stock:0},
      ]
      if(cardsStock == []) return null
      let return_point = 0;
      for(let i=0;i<cardsStock.length; i++){
        return_point = cardsStock[i].return_point
        for(let j=0;j<stockNumber.length;j++){
          if(stockNumber[j].return_point == return_point){
            stockNumber[j].species += 1
            stockNumber[j].actual_stock += cardsStock[i].actual_stock_number
          }
        }
      }
      return stockNumber
    },
    filteredCards: function(){
      let filtered = this.allCards
      if(this.filtering.selectedRarity.length != 0) filtered = filtered.filter(card => this.filtering.selectedRarity.includes(card.card_rarity))
      if(this.filtering.return_point_l != null) filtered = filtered.filter(card => this.filtering.return_point_l <= card.return_point)
      if(this.filtering.return_point_u) filtered = filtered.filter(card => card.return_point <= this.filtering.return_point_u)
      if(this.filtering.price_l) filtered = filtered.filter(card => this.filtering.price_l <= card.price)
      if(this.filtering.price_u) filtered = filtered.filter(card => card.price <= this.filtering.price_u)
      if(this.search != ''){
        filtered = filtered.filter(card => {
          return (
            card.card_rarity.toLowerCase().includes(this.search.toLowerCase()) ||
            card.card_list_number.toLowerCase().includes(this.search.toLowerCase()) ||
            card.pack_number.toLowerCase().includes(this.search.toLowerCase()) ||
            card.options.toLowerCase().includes(this.search.toLowerCase()) ||
            card.card_name.toLowerCase().includes(this.search.toLowerCase()) ||
            card.return_point.toString().includes(this.search.toLowerCase()) ||
            card.price.toString().includes(this.search.toLowerCase())
          );
        })
      }
      return filtered
    },
  },
  methods: {
    checkState: function(){
      console.log(this.$store.state.cardsStock)
    },
    editItem(item){
      console.log("editItem")
      this.editedIndex = this.allCards.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close(){
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save(){
      let cards = this.allCards
      if(this.editedIndex > -1){
        let card = Object.assign({},cards[this.editedIndex])
        card = Object.assign(card, this.editedItem)
        Api.putCardMaster(card)
      }else{
        console.log("adding!")
        cards.push(this.editedItem)
      }
      this.close()
    },
    updateCardsStock: function(){
      Api.putCardsStock(this.cardsStock[this.editedIndex])
    },
    // updateCardsStock: function(){
    //   const url = "https://wp4yigejf6.execute-api.ap-northeast-1.amazonaws.com/dev/cards/stock"
    //   this.axios.put(url,this.cardsStock[this.editedIndex])
    //   .then(function(res){
    //     console.log(res)
    //   })
    //   .catch(function(err){
    //     console.log(err)
    //   })
    // },
    dialogTitle(item){
      return item.card_name+"【"+item.card_rarity+"】{"+item.card_list_number+"}["+item.pack_number+"]"
    },
    clearFilter: function(){
      this.filtering = {
        rarity: ['-','R','RR','RRR','SR','HR','UR'],
        selectedRarity: [],
        return_point_l: null,
        return_point_u: null,
        price_l: null,
        price_u: null,
      }
    },
  }
}
</script>