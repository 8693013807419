<template>
  <v-container>
    <v-card class="ma-2">
      <v-card-title>User</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            sigle-line
            hide-details
          /> 
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11">
            <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            @click:row="openDialog"
          ></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="70%" :retain-focus="false">
      <v-card>
        <table border="1">
          <tr>
              <th>user_id</th>
              <td>{{ selected_user.user_id }}</td>
          </tr>
          <tr>
              <th>first_name</th>
              <td>{{ selected_user.first_name }}</td>
          </tr>
          <tr>
              <th>last_name</th>
              <td>{{ selected_user.last_name }}</td>
          </tr>
          <tr>
              <th>first_name_kana</th>
              <td>{{ selected_user.first_name_kana }}</td>
          </tr>
          <tr>
              <th>last_name_kana</th>
              <td>{{ selected_user.last_name_kana }}</td>
          </tr>
          <tr>
              <th>mail_address</th>
              <td>{{ selected_user.mail_address }}</td>
          </tr>
          <tr>
              <th>point</th>
              <td>{{ selected_user.point }}</td>
          </tr>
          <tr>
              <th>xp</th>
              <td>{{ selected_user.xp }}</td>
          </tr>
          <tr>
              <th>ap</th>
              <td>{{ selected_user.ap }}</td>
          </tr>
          <tr>
              <th>tell_number</th>
              <td>{{ selected_user.tell_number }}</td>
          </tr>
          <tr>
              <th>post_code</th>
              <td>{{ selected_user.post_code }}</td>
          </tr>
          <tr>
              <th>prefectures</th>
              <td>{{ selected_user.prefectures }}</td>
          </tr>
          <tr>
              <th>municipalities</th>
              <td>{{ selected_user.municipalities }}</td>
          </tr>
          <tr>
              <th>address</th>
              <td>{{ selected_user.address }}</td>
          </tr>
          <tr>
              <th>building</th>
              <td>{{ selected_user.building }}</td>
          </tr>
          <tr>
              <th>create_ts</th>
              <td>{{ selected_user.create_ts }}</td>
          </tr>
          <tr>
              <th>last_modified_ts</th>
              <td>{{ selected_user.last_modified_ts }}</td>
          </tr>

        </table>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/modules/api'
export default {
  name: 'AdminUsers',
  data(){
    return{
      headers: [
        {
          text: 'user_id',
          value: 'user_id'
        },
        {
          text: 'last_name',
          value: 'last_name'
        },
        {
          text: 'first_name',
          value: 'first_name'
        },
        {
          text: 'mail_address',
          value: 'mail_address'
        }
      ],
      users: [],
      search: '',
      dialog: false,
      selected_user: ""
    }
  },
  created: async function(){
    Api.getUserAll()
    .then(function(res){
        this.users = res["data"]
        console.log(this.users)
    }.bind(this))
    .catch(function(err){
        console.log(err)
    }.bind(this))
  },
  methods: {
    getAllUsers: function(){
      const url = "https://wp4yigejf6.execute-api.ap-northeast-1.amazonaws.com/dev/user"
      this.axios.get(url,{})
      .then(function(res){
        console.log(res)
      })
    },
    openDialog: function(user){
      this.selected_user = user
      this.dialog = true
    },
    closeDialog: function(){
      this.dialog = false
    }
  }
}
</script>
