import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import cognito from './cognito'

// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// import NotoSansCjkRegular from 'noto-sans-cjk-jp/fonts/NotoSansCJKjp-Regular.woff';
// const fontDescriptors = {
//   notoSans: {
//     normal: 'GenShinGothic-Normal.ttf',
//     // bold: 'noto-sans-cjk-jp/noto-sans-cjk-jp-bold.ttf',
//     // italics: 'noto-sans-cjk-jp/noto-sans-cjk-jp-regular.ttf',
//     // bolditalics: 'noto-sans-cjk-jp/noto-sans-cjk-jp-bold.ttf'
//   }
// };
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = fontDescriptors;


Vue.config.productionTip = false

Vue.use(VueAxios, axios)

new Vue({
  router,
  cognito,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
