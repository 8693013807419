<template>
    <v-container>
        <v-row>
            <v-col>
                <v-btn class="ma-1" @click="openDialogNew">カードマスタ追加</v-btn>
            </v-col>
        </v-row>
        <v-row class="pa-4">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="検索"
                sigle-line
                hide-details
            /> 
        </v-row>
        <v-row>
            <v-col cols="10">
                <v-data-table
                :headers="headers"
                :items="cardMaster"
                :search="search"
                ></v-data-table>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogNew" max-width="60%" :retain-focus="false">
            <v-card>
                <v-card-title>新カード追加</v-card-title>
                <div class="ma-5">
                    <v-row align="center"> 
                        <v-col class="ma-3">
                            <v-text-field
                            hide-details
                            v-model="image_url"
                            label="画像URLから追加" 
                            placeholder="https://www.pokemon-card.com/assets/images/card_images/large/***/*********.jpg">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-btn @click="importFromUrl">インポート</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-divider class="pa-3"></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols=6>
                            <v-text-field
                            hide-details
                            v-model="card_id"
                            label="card_id"
                            hint="card_idは自動的に計算されます．"
                            class="readonly-field"
                            readonly>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            hide-details
                            v-model="pack_number"
                            label="pack_number" >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            hide-details
                            v-model="card_list_number"
                            label="card_list_number" >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols=5>
                            <v-text-field
                            hide-details
                            v-model="card_name"
                            label="card_name" >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            hide-details
                            v-model="card_rarity"
                            label="card_rarity" >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            hide-details
                            v-model="return_point"
                            label="return_point" >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            hide-details
                            v-model="price"
                            label="price" >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols=8>
                            <v-text-field
                            hide-details
                            v-model="pre_image"
                            label="pre_image_url" >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            hide-details
                            v-model="publish"
                            label="publish" >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            hide-details
                            v-model="options"
                            label="options" >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDialogNew">キャンセル</v-btn>
                        <v-btn @click="postCardMaster">追加</v-btn>
                    </v-card-actions>
                    <v-row>
                        <v-col cols=2>
                            <v-img 
                            :src="pre_image" 
                            ></v-img>
                        </v-col>
                        <v-col>
                            <v-row>・<a href="https://www.pokemon-card.com/card-search/index.php">ポケカ公式サイト</a>より画像のURLをコピペしてインポートすることで，一部の項目を自動的に追加できる．</v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-card-text>
                        <div class="table-container">
                            <table class="dialog">
                            <thead>
                                <tr>
                                    <th><b>項目名</b></th>
                                    <th>説明</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>card_id</b></td>
                                    <td>pack_numberとcard_list_numberのスラッシュ前までとoptionsを結合したもので，自動で計算される．</td>
                                </tr>
                                <tr>
                                    <td><b>pack_number</b></td>
                                    <td>カードの左下に黒背景白文字で記載されているパック番号．カード画像のURLにおける後ろから2番目の項目．</td>
                                </tr>
                                <tr>
                                    <td><b>card_list_number</b></td>
                                    <td>カードの左下に000/000の形式で書かれている通し番号．</td>
                                </tr>
                                <tr>
                                    <td><b>card_name</b></td>
                                    <td>カードの名前．</td>
                                </tr>
                                <tr>
                                    <td><b>card_rarity</b></td>
                                    <td>カード左下に記載のカードのレアリティ．</td>
                                </tr>
                                <tr>
                                    <td><b>return_point</b></td>
                                    <td>カードを還元する時にもらえるポイント．priceより少なく設定する．</td>
                                </tr>
                                <tr>
                                    <td><b>price</b></td>
                                    <td>カードの値段．現在の相場を設定する．</td>
                                </tr>
                                <tr>
                                    <td><b>pre_image_url</b></td>
                                    <td>圧縮前の元画像．この画像を規定のサイズに圧縮後S3に保存する．公式に画像がある場合はそれを，ない場合はカードラッシュから引用する．</td>
                                </tr>
                                <tr>
                                    <td><b>publish</b></td>
                                    <td>1に設定するとオリパで使用できるようになる．0だとオリパで使用できなくなる．</td>
                                </tr>
                                <tr>
                                    <td><b>options</b></td>
                                    <td>"PSA10"等の追加情報を表す．付与した場合，idを一意に保つためpost時にunixtimeを付与する．</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </v-card-text>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Api from '@/modules/api'
import store from '../store';

export default {
    name: 'cardMaster',
    data(){
        return{
            headers: [
                { 
                    text: 'Name', 
                    value: 'card_name' 
                },
                {
                    text: 'Pack Number',
                    value: 'pack_number',
                },
                { 
                    text: 'Card Number', 
                    value: 'card_list_number' 
                },
                {
                    text: 'Card Rarity',
                    value: 'card_rarity'
                },
                {
                    text: 'Return Point',
                    value: 'return_point'
                }
            ],
            cardMaster: [],
            search: '',
            dialogNew: false,
            image_url: "",
            pack_number: "",
            card_list_number: "",
            card_rarity: "",
            card_name: "",
            pre_image: "",
            return_point: "",
            price: "",
            publish: 1,
            options: "-",
        }
    },
    mounted: function(){
        this.getCardMaster()
    },
    computed: {
        card_id: function(){
            if(this.options == "" || this.options == "-"){
                return this.pack_number+"_"+this.card_list_number.split("/")[0]
            }else{
                const now = new Date();
                var unixtime = Math.floor(now.getTime() / 1000);
                return this.pack_number+"_"+this.card_list_number.split("/")[0]+"_"+this.options+"_"+unixtime.toString()
            }
        }
    },
    methods: {
        getCardMaster: function(){
            Api.getCardMaster()
            .then(function(res){
                this.cardMaster = res["data"]
                console.log(this.cardMaster)
            }.bind(this))
            .catch(function(err){
                console.log(err)
            }.bind(this))
        },
        openDialogNew: function(){
            this.dialogNew = true
        },
        closeDialogNew: function(){
            this.dialogNew = false
        },
        importFromUrl: function(){
            var url_splitted = this.image_url.split("/")
            var l = url_splitted.length
            this.pack_number = url_splitted[l-2]
            this.card_id = url_splitted[l-2] + "_" + url_splitted[l-1].split(".")[0]
            this.pre_image = this.image_url
        },
        postCardMaster: function(){
            Api.postCardMaster(
                this.card_id,
                this.pack_number,
                this.card_list_number,
                this.card_name,
                this.card_rarity,
                this.return_point,
                this.price,
                this.publish,
                this.options,
                this.pre_image
            ).then(function(res){
                console.log(res)
                this.closeDialogNew()
            }.bind(this))
            .catch(function(err){
                console.log(err)
                alert(err);
            }.bind(this))
        }
    }
}
</script>

<style>
.readonly-field {
  background-color: #f5f5f5;
  color: #666;
}

.table-container {
  max-height: 400px;
  overflow: auto;
}

thead {
  position: sticky;
  top: 0;
  background-color: white;
}
</style>