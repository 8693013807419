<template>
  <v-container fluid>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>オリパ一覧</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-text>
              <v-data-table v-model="selectedOripa" :items="oripas" :headers="headersOripa" item-key="oripa_id"
                :single-select="true" show-select :items-per-page="-1" hide-default-header hide-default-footer>
                <template v-slot:item.id="{item}">No.{{ item.oripa_id }}</template>
                <template v-slot:item.publish="{item}">
                  <v-chip v-if="now<item.sale_ts||item.publish!=1" color="orange" class="white--text">未販売</v-chip>
                  <v-chip v-else-if="getRestLot(item.oripa_stock)==0" color="red" class="white--text">SOLDOUT</v-chip>
                  <v-chip v-else-if="item.publish==2" color="blue" class="white--text">ボーナス</v-chip>
                  <v-chip v-else color="green" class="white--text">販売中</v-chip>
                </template>
                <template v-slot:item.name="{item}">【{{item.price}}pt】{{ item.oripa_name }}</template>
                <template v-slot:item.content="{item}">
                  <v-chip label outlined>{{ getRestLot(item.oripa_stock) }} / {{ getLot(item.oripa_stock) }}</v-chip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card v-if="selectedOripa.length>0" class="mt-5">
      <v-card-title>
        No.{{ selectedOripa[0].oripa_id }} 【{{selectedOripa[0].price}}pt】{{ selectedOripa[0].oripa_name }}
        <v-spacer></v-spacer>
        {{ getRestLot(selectedOripa[0].oripa_stock) }} / {{ getLot(selectedOripa[0].oripa_stock) }}
      </v-card-title>
      <v-card-subtitle>
        S: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='S').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='S').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
        A: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='A').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='A').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
        B: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='B').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='B').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
        C: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='C').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='C').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }} , 
        LAST: {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='LAST').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0) }} / {{ selectedOripa[0].oripa_stock.filter(item=>item["prize"]=='LAST').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0) }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>確定利益</v-card-subtitle>
              <v-card-text>
                <p class="mb-0">実ガチャ回数 {{ orders?.gacha_count }}回</p>
                <p class="mb-0">売上 {{ Math.round(orders?.gacha_count*selectedOripa[0].price*0.964) }}円</p>
                <p class="mb-0">利益 {{ Math.round(orders?.gacha_count*selectedOripa[0].price*0.964 - orders?.loss) }}円</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-subtitle>未実現最小利益</v-card-subtitle>
              <v-card-text>
                <p class="mb-0">残ガチャ回数 {{ getRestLot(selectedOripa[0].oripa_stock) }}回</p>
                <p class="mb-0">売上 {{ Math.round(selectedOripa[0].price*getRestLot(selectedOripa[0].oripa_stock)*0.964) }}</p>
                <p class="mb-0">利益 {{ Math.round(getRestProfit(selectedOripa[0])) }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-card-text>
        <v-row>
          <v-col cols="auto">
            <v-btn-toggle v-model="cardFilter" multiple color="cyan">
              <v-btn>残カード</v-btn>
              <v-btn>ガチャ済</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn icon @click="dialogDeliveryCards=true"><v-icon>mdi-cards</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-data-table :items="filteredCards" :headers="headersCard" item-key="card_id" @click:row="selectCard"
          :sort-by="['price']" :sort-desc="[true]" disable-pagination hide-default-footer fixed-header>
          <template v-slot:item.image="{item}">
            <v-img :src="item.image" class="ma-1" width="40px"></v-img>
          </template>
          <template v-slot:item.stock_info="{item}">
            {{ item.sum_stock }}[{{ item.rush_stock }}]
          </template>
          <template v-slot:item.card_info="{item}">
            【{{ item.options }}】{{ item.card_name }}[{{ item.card_rarity }}]
          </template>
          <template v-slot:item.pp="{item}">
            {{ item.return_point }}pt / {{ item.price }}円
          </template>
          <template v-slot:item.rush_price="{item}">
            <span :class="{'red lighten-2': item.rush_price > item.price}">{{ item.rush_price }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogDeliveryCards" fullscreen>
      <v-card>
        <v-card-actions>
          <v-btn @click="dialogDeliveryCards=false" text><v-icon>mdi-close</v-icon></v-btn>
          <v-card-title>配送予定カード一覧</v-card-title>
        </v-card-actions>
        <v-card-text>
          <p>合計枚数：{{ cardsDelivery.reduce((acc, card) => acc + card.count, 0) }}, 
            合計価格：{{ cardsDelivery.reduce((acc, card) => acc + card.count*card.price, 0) }} 円</p>
          <v-data-table :headers="headersDeliveryCards" :items="cardsDelivery" item-key="card_id"
            :items-per-page="-1" fixed-header show-select @click:row="selectCard">
            <template v-slot:item.image="{item}">
              <v-img :src="item.image" class="ma-1" width="40px"></v-img>
            </template>
            <template v-slot:item.count="{item}">
              {{ item.count }}枚
            </template>
            <template v-slot:item.card_info="{item}">
              【{{ item.card_rarity }}】{{ item.card_list_number }} {{ item.pack_number }} 〘{{ item.options }}〙
            </template>
            <template v-slot:item.pp="{item}">
              {{ item.return_point }}pt / {{ item.price }}円
            </template>
            <template v-slot:item.rush_price="{item}">
              <span :class="{'red lighten-2': item.rush_price > item.price}">{{ item.rush_price }}円</span>
            </template>
            <template v-slot:item.stock_info="{item}">
              {{ item.sum_stock }}[{{ item.rush_stock }}]
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import Api from '@/modules/api'

export default {
  name: 'OripaData',
  data () {
    const today = new Date().toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"});
    const nowAll = new Date(today)
    const now = this.formatDate(nowAll, 'yyyy-MM-ddTHH:mm:ss');
    return {
      now: now,
      selectedOripa: [],
      cardFilter: [0],
      headersOripa: [
        {value:'id'},
        {value:'publish'},
        {value:'name'},
        {value:'content'}
      ],
      orders: null,
      headersCard: [
        {value:'image'},
        {value:'prize'},
        {value:'card_info'},
        {value:'pp'},
        {text:'相場rush',align:'right',value:'rush_price'},
        {text:'在庫[rush]',value:'stock_info'},
        {text:'残数',value:'rest_lot'}
      ],
      dialogDeliveryCards: false,
      headersDeliveryCards: [
        {value:'image'},
        {text:'枚数',value:'count'},
        {value:'card_info'},
        {value:'card_name'},
        {value:'pp'},
        {text:'相場rush',align:'right',value:'rush_price'},
        {text:'在庫[rush]',value:'stock_info'},
      ],
    }
  },
  computed: {
    oripas: function(){
      let oripas = this.$store.getters.oripas
      if(oripas == null) return []
      oripas.sort((a, b) => b.oripa_id - a.oripa_id)
      this.selectedOripa = [oripas[0]]
      return oripas
    },
    cardsDelivery: function(){
      let result = this.allCards
      const cardsDelivery = this.$store.getters.cardsDelivery
      if(cardsDelivery == null) return []
      let deliveryCards = Object.values(cardsDelivery.reduce((acc, card) => {
        if (acc[card.card_id]) {
          acc[card.card_id].count++;
        } else {
          acc[card.card_id] = { ...card, count: 1 };
        }
        return acc;
      }, {}))
      deliveryCards.sort((a, b) => b.price - a.price)
      // result = result.filter(card => deliveryCards.map(item=>item.card_id).includes(card.card_id))
      result = deliveryCards.map(card => ({ ...result.find(obj => obj.card_id === card.card_id), ...card }));
      console.log("result",result)
      return result
    },
    allCards: function(){
      const allCards = this.$store.getters.cardsStock
      if(allCards == null) return []
      return allCards
    },
    filteredCards: function(){
      let filtered = this.allCards
      const oripa_stock = this.selectedOripa[0]?.oripa_stock
      filtered = filtered.filter(card => oripa_stock.map(item=>item.card_id).includes(card.card_id))
      filtered.map(card => {
        const stock = oripa_stock.find(item => item.card_id == card.card_id)
        card.rest_lot = stock.rest_lot
        card.prize = stock.prize
      })
      if(this.cardFilter.includes(0)&&!this.cardFilter.includes(1)){
        filtered = filtered.filter(card => card.rest_lot > 0)
      }else if(!this.cardFilter.includes(0)&&this.cardFilter.includes(1)){
        filtered = filtered.filter(card => card.rest_lot == 0)
      }else if(!this.cardFilter.includes(0)&&!this.cardFilter.includes(1)){
        filtered = []
      }
      console.log("filtered: ",filtered)
      // if(this.filtering.selectedRarity.length != 0) filtered = filtered.filter(card => this.filtering.selectedRarity.includes(card.card_rarity))
      // if(this.filtering.return_point_l != null) filtered = filtered.filter(card => this.filtering.return_point_l <= card.return_point)
      // if(this.filtering.return_point_u) filtered = filtered.filter(card => card.return_point <= this.filtering.return_point_u)
      // if(this.filtering.price_l) filtered = filtered.filter(card => this.filtering.price_l <= card.price)
      // if(this.filtering.price_u) filtered = filtered.filter(card => card.price <= this.filtering.price_u)
      return filtered
    },
  },
  watch: {
    selectedOripa: function(next,prev){
      console.log("next: ",next)
      if(next.length>0){
        this.orders = this.getOrders()
      }
    }
  },
  methods: {
    getRestLot: function(stock){
      return stock.filter(item=>item["prize"]!='LAST').map(item=>Number(item["rest_lot"])).reduce((sum, element) => sum + element, 0)
    },
    getLot: function(stock){
      return stock.filter(item=>item["prize"]!='LAST').map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0)
    },
    formatDate (date, format) {
      format = format.replace(/yyyy/g, date.getFullYear());
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
      format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
      format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
      return format;
    },
    getOrders:function(){
      Api.getOripaOrders(this.selectedOripa[0].oripa_id).then(res=>{
        const orders = res
        console.log(orders)
        this.orders = orders
      })
    },
    getRestProfit: function(oripa){
      const restLot = this.getRestLot(oripa.oripa_stock)
      const sales = restLot * oripa.price * 0.964
      const oripa_stock = oripa.oripa_stock
      let loss = 0
      for(let i=0;i<oripa_stock.length;i++){
        if(oripa_stock[i].rest_lot>0){
          if(oripa_stock[i].return_point == null){
            loss += oripa_stock[i].rest_lot * oripa_stock[i].price
          }else if(oripa_stock[i].return_point == 580){
            loss += oripa_stock[i].rest_lot * (oripa_stock[i].return_point+200)
          }else if(oripa_stock[i].return_point == 360){
            loss += oripa_stock[i].rest_lot * (oripa_stock[i].return_point+20)
          }else{
            loss += oripa_stock[i].rest_lot * oripa_stock[i].return_point
          }
          
        }
      }
      return sales - loss
    },
    selectCard(item){
      this.$router.push({name: 'cardStatistics', params: {item: item}})
    },
  }
}
</script>