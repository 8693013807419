<template>
    <v-container fluid>
        <v-btn v-on:click="toCardStatisticsList">戻る</v-btn>
        <v-card class="ma-2">
            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <v-img :src="item.image" width=60%></v-img>
                    </v-col>
                    <v-col cols="10">
                        <v-row>
                            <v-col cols=5>
                                <v-text-field
                                hide-details
                                v-model="item.card_name"
                                append-icon="mdi-pencil"
                                label="card_name" >
                                </v-text-field>
                            </v-col>
                            <v-col cols=5>
                                <v-btn v-on:click="initValue">元の値に戻す</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols=5>
                                <v-text-field
                                hide-details
                                v-model="item.card_id"
                                label="card_id"
                                variant="solo"
                                hint="card_idは自動的に計算されます．"
                                readonly>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                hide-details
                                v-model="item.pack_number"
                                variant="solo"
                                readonly
                                label="pack_number">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                hide-details
                                v-model="item.card_list_number"
                                variant="solo"
                                readonly
                                label="card_list_number">
                                </v-text-field>
                            </v-col>
                            <!-- <v-col cols="3">pack: {{ item.card_list_number }}</v-col>
                            <v-col cols="3">rarity: {{ item.card_rarity }}</v-col>
                            <v-col cols="4">point: {{ item.return_point }}</v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        hide-details
                        v-model="item.card_rarity"
                        label="card_rarity"
                        append-icon="mdi-pencil" >
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                        hide-details
                        v-model="item.return_point"
                        label="return_point"
                        append-icon="mdi-pencil" >
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                        hide-details
                        v-model="item.price"
                        label="price"
                        append-icon="mdi-pencil" >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols=8>
                        <v-text-field
                        hide-details
                        v-model="item.pre_image"
                        label="image_url" >
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn-toggle v-model="item.publish">
                            <v-btn :value="1">公開</v-btn>
                            <v-btn :value="0">非公開</v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col>
                        <v-text-field
                        hide-details
                        v-model="item.options"
                        label="options"
                        append-icon="mdi-pencil" >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="justify-end">
                    <v-btn variant="primary" v-on:click="saveValue">保存</v-btn>
                </v-row>
                <v-row>
                    <v-col cols="5">avg price: {{ avgPrice }}</v-col>
                    <v-col cols="5">trim avg price: {{ trimAvgPrice }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="ma-2">
            <v-card-text>
                <v-card-title>Recent Price and Stock</v-card-title>
                <v-row>
                    <v-data-table
                        :headers="shopsForHeader"
                        :items="[shopPriceRecent,shopStockRecent]"
                    ></v-data-table>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card>
            <GChart
                type="LineChart"
                :data="priceDataChart"
                :options="chartOptions"
            ></GChart>
        </v-card>
                
    </v-container>
</template>

<script>
import store from '../store'
import { GChart } from 'vue-google-charts/legacy'
import Api from '@/modules/api'
export default {
    components: { GChart },
    props: [ 'item' ],
    data() {
        return {
            shops: [],
            shop_data: [],
            dateKeys: new Set(),
            priceData: {},
            shopPriceRecent: {"item": "Price"},
            shopStockRecent: {"item": "Stock"},
            chartOptions: {
                title: "各ショップの販売価格推移",
                seriesType: "line",
                pointSize:3,
                interpolateNulls: true,
                height: 500,
                vAxis: {title: '販売価格'}
                // chartArea:{left:20,top:20,width:20,height:20}
            },
            search: '',
            prev_item: {}
        }   
    },
    created: async function(){
        this.prev_item = JSON.parse(JSON.stringify(this.item));
        await this.getShops()
        for (const shop of this.shops) {
            await this.getCardMaster(shop[0]);
        }
    },
    computed: {
        shopsForHeader(){
            var header = []
            header.push({text: 'Item', value: 'item'})

            for( var shop of this.shops){
                header.push({text: shop[1], value: shop[0]})
            }
            return header
        },
        avgPrice(){
            var sum = 0
            var count = 0
            for (var shop_id in this.shopPriceRecent){
                var price = this.shopPriceRecent[shop_id]
                if(typeof(price) == "number"){
                    sum += price
                }
                count += 1
            }
            return Math.round(sum/count)
        },
        trimAvgPrice(){
            var sum = 0
            var count = 0
            var min = 10000000000
            var max = 0
            for (var shop_id in this.shopPriceRecent){
                var price = this.shopPriceRecent[shop_id]

                if(typeof(price) != "number"){
                    continue
                }

                if(price < min){
                    min = price
                }
                if(price > max){
                    max = price
                }
                sum += price
                count += 1
            }
            return Math.round((sum-max-min)/(count-2))
        },
        priceDataChart(){
            var header =  ["時刻"]
            for(var key in this.priceData){
                header.push(key)
            }
            header.push("avg")
            header.push("trim avg")
            var data = [
                header
            ]

            for(var datekey of this.dateKeys){
                var sum = 0
                var count = 0
                var min = 10000000000
                var max = 0
                var datatmp = [new Date(datekey*1000)]
                for(var key in this.priceData){
                    if(this.priceData[key][datekey] == undefined){
                        datatmp.push(null)
                    }else{
                        var price = this.priceData[key][datekey].price
                        sum += price
                        count += 1
                        datatmp.push(price)
                        if(price > max){
                            max = price
                        }
                        if(price < min){
                            min = max
                        }
                    }
                }
                datatmp.push(Math.round(sum/count))
                datatmp.push(Math.round((sum-max-min)/(count-2)))
                data.push(datatmp)
            }

            // console.log(data)
            return data
        }
    },
    methods: {
        async getShops(){
            const url = "https://wp4yigejf6.execute-api.ap-northeast-1.amazonaws.com/dev/shops/"
            const jwtToken = store.getters.jwtToken
            if(jwtToken != null){
                await this.axios.get(url,{
                    headers: {Authorization: jwtToken}
                }
                ).then(function(res){
                    console.log(res)
                    this.shops = res["data"]
                }.bind(this))
                .catch(function(err){
                    console.log(err)
                })
            }else{
                console.log("jwt token is invalid")
            }
        },
        async getCardMaster(shop_id){
            const stock_id = this.item.card_id + "_" + shop_id + "_A" //後でランクを変更出来るようにする
            const url = "https://wp4yigejf6.execute-api.ap-northeast-1.amazonaws.com/dev/cards/statistics/" + stock_id
            // S7R/039948_T_HIGANANOKETSUI_yuyutei_A
            const jwtToken = store.getters.jwtToken
            if(jwtToken != null){
                await this.axios.get(url,{
                    headers: {Authorization: jwtToken}
                }).then(function(res){
                    var keys = Object.keys(res["data"])
                    keys.sort();
                    var recent_data = res["data"][keys[keys.length-1]]
                    this.$set(this.shopPriceRecent, shop_id, recent_data.price)
                    this.$set(this.shopStockRecent, shop_id, recent_data.stock)

                    for(var unixtime in res["data"]){
                        this.dateKeys.add(unixtime)
                    }
                    this.$set(this.priceData, shop_id, res["data"])

                }.bind(this))
                .catch(function(err){
                    console.log(err)
                })
            }else{
                console.log("jwt token is invalid")
            }
        },
        selectCard(item){
            // console.log(item)
            this.$router.push({name: 'cardStatistics', params: {item: item}})
        },
        convertUnixToDate(timestamp){
            var time = new Date(timestamp*1000)
            return time.getFullYear() + "/" + (time.getMonth()+1) + "/" + time.getDate();
        },
        toCardStatisticsList(){
            this.$router.push('cardStatisticsList')
        },
        initValue(){
            console.log(this.item)
            console.log(this.prev_item)
            for(var key in this.prev_item){
                this.$set(this.item, key, this.prev_item[key])
            }
        },
        saveValue(){
            var card = {
                card_id: this.item.card_id,
                card_name: this.item.card_name,
                card_rarity: this.item.card_rarity,
                return_point: this.item.return_point,
                price: this.item.price,
                publish: this.item.publish,
                options: this.item.options,
            }

            Api.putCardMaster(
                card
            ).then(function(res){
                console.log(res)
                alert("正常に更新が終了しました．")
            }.bind(this))
            .catch(function(err){
                console.log(err)
                alert(err);
            }.bind(this))
        }
    }
}
</script>

<style lang="scss">

</style>
