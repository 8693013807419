<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>Oripa Simulator</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="6"><v-text-field filled v-model="sim.all_lots" label="全口数" suffix="【口】"></v-text-field></v-col>
                  <v-col cols="6"><v-text-field filled v-model="sim.p_lot" label="1ロット" suffix="【P】"></v-text-field></v-col>
                </v-row>
                <!-- <v-row class="mt-0">
                  <v-col><v-text-field filled v-model="sim.p_purchase_high" label="高額カード総額" suffix="【円】"></v-text-field></v-col>
                </v-row> -->
                <v-row>
                  <v-data-table :headers="sim.headersBlank" :items="sim.p_hazure" :items-per-page="-1">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>高額以外のカードの排出率</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="addRateBlank">ADD</v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.return_point="props">
                      <v-edit-dialog>
                        {{ props.item.return_point }}
                        <template v-slot:input>
                          <v-text-field v-model="props.item.return_point" single-line></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:item.amounts="props">
                      <v-edit-dialog>
                        {{ props.item.amounts }}
                        <template v-slot:input>
                          <v-text-field v-model="props.item.amounts" single-line></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>
                </v-row>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="6">
                <v-data-table :headers="sim.headersPrize" :items="sim.prize" :items-per-page="-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>高額カードの排出率</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="addRatePrize">ADD</v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.return_point="props">
                    <v-edit-dialog>
                      {{ props.item.return_point }}
                      <template v-slot:input>
                        <v-text-field v-model="props.item.return_point" single-line></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.amounts="props">
                    <v-edit-dialog>
                      {{ props.item.amounts }}
                      <template v-slot:input>
                        <v-text-field v-model="props.item.amounts" single-line></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                </v-data-table>
                <v-card>
                  <v-card-title>Result</v-card-title>
                  <v-card-text>
                    <v-text-field readonly v-model="cvp_return_rate" suffix="【%】" label="ポイント還元率の損益分岐点"></v-text-field>
                    <v-text-field readonly v-model="return_rate" suffix="【%】" label="高額以外のカードのポイント還元率"></v-text-field>
                    <v-text-field readonly v-model="need_money" suffix="【円】" label="買い占めるのに必要な金額"></v-text-field>
                    <v-text-field readonly v-model="profit" suffix="【円】" label="利益"></v-text-field>
                  </v-card-text>
                </v-card>
                <p class="red--text mt-5">※買い占めるのに必要な金額以上の高額カードで構成すると破綻する可能性がある。</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card class="mt-10">
        <v-card-title>Oripa Database</v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel v-for="oripa in oripaData" :key="oripa.oripa_id">
              <v-expansion-panel-header>
                <!-- <div>No.{{ oripas[0].oripa_id }}</div>
                <div>
                  <v-chip v-if="oripas[0].publish==1" label class="ml-2" outlined color="success">Open</v-chip>
                  <v-chip v-else label class="ml-2" outlined color="error">Close</v-chip>
                </div> -->
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="9">
                      No.{{ oripa.oripa_id }}
                      <v-chip v-if="oripa.publish==1" label class="ml-2 mr-2" outlined color="success">Open</v-chip>
                      <v-chip v-else label class="ml-2 mr-3" outlined color="error">Close</v-chip>
                      {{ oripa.price }}<v-icon>mdi-alpha-p-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="4">
                      <h3>{{ oripa.oripa_name }}</h3>
                    </v-col>
                    <v-col cols="6">{{ oripa.all_lot }}</v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table :items="oripa.oripa_stock" :headers="oripa_stock_headers" :items-per-page="-1" hide-default-footer>
                  <template v-slot:item.image="{ item }" >
                    <v-img :src="item.image" class="ma-1" width="50px"></v-img>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import store from '../store'

  export default {
    name: 'AdminOripa',
    data(){
      return{
        sim: {
          all_lots: null,
          p_lot: null,
          // p_purchase_high: null,
          p_hazure: [
            {composition:'80✕1',return_point:80,amounts:0},
            {composition:'120✕1',return_point:120,amounts:0},
            {composition:'80✕2',return_point:160,amounts:0},
            {composition:'380',return_point:380,amounts:0},
            {composition:'680',return_point:680,amounts:0},
            {composition:'380✕2',return_point:760,amounts:0},
            {composition:'380✕3',return_point:1140,amounts:0},
            {composition:'680✕2',return_point:1360,amounts:0},
            {composition:'680✕3',return_point:2040,amounts:0},
          ],
          headersBlank: [
            {text:'構成',value:'composition'},
            {text:'還元ポイント（P）',value:'return_point'},
            {text:'排出数（枚）',value:'amounts'}
          ],
          prize: [
            // {return_point:0,amounts:0}
          ],
          headersPrize: [
            {text:'還元ポイント（P）',value:'return_point'},
            {text:'排出数（枚）',value:'amounts'}
          ]
        },
        oripas: [],
        oripa_stock_headers: [
          {text:'景品',value:'prize'},
          {text:'カード',value:'image'},
          {text:'カードID',value:'card_id'},
          {text:'還元P',value:'return_point'},
          {text:'残り',value:'rest_lot'},
          {text:'全枠',value:'lot'}
        ],
      }
    },
    computed: {
      p_purchase_high: function(){
        let point = 0
        for(let i=0;i<this.sim.prize.length;i++){
          point += this.sim.prize[i].return_point*this.sim.prize[i].amounts
        }
        return point
      },
      cvp_return_rate: function(){
        return 100*(1 - this.p_purchase_high / (this.sim.p_lot*this.sim.all_lots))
      },
      return_rate: function(){
        let return_rate = 0
        for(let i=0;i<this.sim.p_hazure.length; i++){
          return_rate += this.sim.p_hazure[i].return_point * (this.sim.p_hazure[i].amounts/this.sim.all_lots) / this.sim.p_lot
        }
        return return_rate
      },
      need_money: function(){
        // all_lot*p_lot*(1-return_rate)
        return Math.trunc(this.sim.all_lots*this.sim.p_lot*(1-this.return_rate))
      },
      profit: function(){
        return Math.trunc(this.need_money - this.p_purchase_high)
      },
      oripaData: function(){
        if(this.oripas.length == 0) return []
        let oripas = this.oripas
        for(let i=0;i<oripas.length;i++){
          for(let j=0;j<oripas[i].oripa_stock.length;j++){
            // oripas[i].oripa_stock[j]["image"] = "https://www.pokemon-card.com/assets/images/card_images/large/"+oripas[i].oripa_stock[j]["card_id"]+".jpg"
          }
        }
        return oripas
      },
    },
    mounted: function(){
      this.getOripas()
    },
    methods: {
      addRateBlank: function(){
        this.sim.p_hazure.push({composition:"",return_point:0,amounts:0})
      },
      addRatePrize: function(){
        this.sim.prize.push({return_point:0,amounts:0})
      },
      calculate: function(){
        console.log("cal!")
        this.sim.cvp_return_rate = 1 - this.sim.p_purchase_high / (this.sim.p_lot*this.sim.all_lots)
      },
      getOripas: function(){
        const url = "https://wp4yigejf6.execute-api.ap-northeast-1.amazonaws.com/dev/oripa/open"
        const jwtToken = store.getters.jwtToken
        this.axios.get(url,{
          headers: {Authorization: jwtToken}
        })
        .then(function(res){
          console.log(res)
          this.oripas = res.data
        }.bind(this))
        .catch(function(err){
          console.log(err)
        })
      },
    }
  }
</script>